import React from 'react';
import GeneralRegisterButton, {
    GeneralRegisterButtonProps
} from "@/components/Program/RegisterButtons/GeneralRegisterButton.tsx";

const MainRegisterButton = (props : GeneralRegisterButtonProps): React.ReactElement => (
    <GeneralRegisterButton
        buttonText="Register"
        buttonTextAction="Registering"
        showCourseQuestions={true}
        {...props}
    />
);

export default MainRegisterButton;
