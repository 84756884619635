import ModalBase from './ModalBase';
import {ProgramCourse} from "../Providers/ProgramsProvider";

type Props = {
    course : ProgramCourse | null;
    handleShowModal : (show : boolean) => void;
    show : boolean;
};

const CourseDescriptionModal = ({course, handleShowModal, show} : Props) => {
    const handleCloseModal = () => {
        handleShowModal(false);
    };

    return (
        <ModalBase
            show={show}
            title=""
            size="lg"
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={handleCloseModal}
        >
            {course && (
                <div className="d-flex flex-column align-items-start">
                    <h3>{course.name}</h3>
                    <ul>
                        <li><strong>Category:</strong> {course.category}</li>
                        <li><strong>Number:</strong> {course.courseNumber}</li>
                        <li><strong>Dates:</strong> {course.dates}</li>
                        <li><strong>Days:</strong> {course.meetingDays}</li>
                        <li><strong>Time:</strong> {course.meetingTime}</li>
                        <li><strong>Cost:</strong> ${course.originalCost}</li>
                        <li><strong>Section #:</strong> {course.sectionNumber}</li>
                        <li><strong>Delivery Method:</strong> {course?.deliveryMethod}</li>
                        <li><strong>Credits:</strong> {course.credits}</li>
                        <li><strong>Term:</strong> {course.term}</li>
                        <li><strong>School:</strong> {course?.school.fullName}</li>
                        <li><strong>Description: </strong> <br />{course.description}</li>
                        <li><strong>Notes: </strong>{course.notes}</li>
                    </ul>
                </div>
            )}
        </ModalBase>
    );
};

export default CourseDescriptionModal;
