import React from 'react';
import GeneralRegisterButton, {
    GeneralRegisterButtonProps
} from "@/components/Program/RegisterButtons/GeneralRegisterButton.tsx";

const RegisteredWithCancelButton = (props : GeneralRegisterButtonProps): React.ReactElement => (
    <GeneralRegisterButton
        buttonText="You are Registered - Click to Cancel Registration"
        buttonTextAction="Cancelling"
        {...props}
    />
);

export default RegisteredWithCancelButton;
