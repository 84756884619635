import {faSignInAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Form, Formik, FormikValues} from "formik";
import React, {useCallback, useState} from 'react';
import {Alert, Button, Col, Row, Spinner} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import * as yup from 'yup';
import {useNotice} from "../Providers/NoticeProvider";
import {isState, phoneValidation, postalCodeUS, states, yupDateRegex} from "../../Helpers/validationHelpers";
import {apiEndpoint} from "../Providers/JWTProvider";
import DigitalSignatureField from "./Fields/DigitalSignatureField";
import PhoneField from "./Fields/PhoneField";
import StandardField from "./Fields/StandardField";
import GuardianForm from "./GuardianForm";

const RegisterCommunityMemberForm = () => {
    let history = useHistory();
    const [notice] = useNotice();
    const [formIsModified, setFormIsModified] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showConfirmationMessage, setShowConfirmationMessage] = useState<boolean>(false);
    const [formHasBeenSubmitted, setFormHasBeenSubmitted] = useState(false);
    const [communityMemberAlreadyExists, setCommunityMemberAlreadyExists] = useState(false);

    const handleCreateRegistration = async (values: FormikValues) => {
        const url = new URL(`/v1/community/register-member`, apiEndpoint);
        let init: RequestInit = {headers: new Headers({'Content-Type': 'application/json'}), method: 'PUT'};
        init.body = JSON.stringify(values);
        const response = await fetch(url.toString(), init);

        if (response.status === 409) {
            setCommunityMemberAlreadyExists(true);
            setLoading(false);
            return;
        }

        if (response.status !== 200) {
            return null;
        }

        setLoading(false);
        setShowConfirmationMessage(true);
    };

    const handleSetFormIsModified = useCallback(async (formIsModified: boolean) => {
        setFormIsModified(formIsModified);
    }, [setFormIsModified]);

    const initialValues = {
        nameFirst: '',
        nameLast: '',
        dob: '',
        grade: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        homePhone: '',
        gender: '',
        email: '',
        printedSignature: '',
        digitalSignature: '',
        guardians: [
            {
                nameFirst: '',
                nameLast: '',
                relationship: '',
                relationshipOther: '',
                phone1: '',
                phone1Type: '',
                phone2: '',
                phone2Type: '',
                email: '',
            },
            {
                nameFirst: '',
                nameLast: '',
                relationship: '',
                relationshipOther: '',
                phone1: '',
                phone1Type: '',
                phone2: '',
                phone2Type: '',
                email: '',
            },
        ],
    };

    const schema = yup.object({
        nameFirst: yup.string().required('First Name is required'),
        nameLast: yup.string().required('Last Name is required'),
        dob: yupDateRegex.required('Date of Birth is required'),
        grade: yup.string().required('Grade is required'),
        address: yup.string().required(`Address is required`).nullable(),
        city: yup.string().required(`City is required`).nullable(),
        state: isState.required(`State is required`).nullable(),
        zip: postalCodeUS.required(`Zip is required`).nullable(),
        homePhone: phoneValidation.transform((value) => {
            return value !== '' ? value : 'null';
        }).nullable(),
        email: yup
            .string()
            .transform((value) => {
                return value === null ? '' : value;
            })
            .email("Email must be valid.")
            .required(`Email is required`),
        printedSignature: yup.string().required('Printed Signature is required'),
        digitalSignature: yup.string().required('Digital Signature is required'),
        guardians: yup
            .array()
            .of(
                yup.object().shape({
                    nameFirst: yup
                        .string()
                        .transform((value) => {
                            return value === null ? '' : value;
                        })
                        .required(
                            "First Name is required."
                        ),
                    nameLast: yup
                        .string()
                        .transform((value) => {
                            return value === null ? '' : value;
                        })
                        .required(
                            "Last Name is required."
                        ),
                    relationship: yup
                        .string()
                        .transform((value) => {
                            return value === null ? '' : value;
                        })
                        .required(
                            "Relationship is required."
                        ),
                    relationshipOther: yup
                        .string()
                        .transform((value) => {
                            return value === null ? '' : value;
                        })
                        .when("relationship", {
                            is: (relationship: string) =>
                                relationship === "Other",
                            then: yup
                                .string()
                                .required(
                                    "You must enter Relationship Other when Relationship is Other."
                                ),
                        }),
                    phone1: phoneValidation
                        .required(
                            "Phone 1 is required."
                        ),
                    phone1Type: yup
                        .string()
                        .transform((value) => {
                            return value === null ? '' : value;
                        })
                        .required(
                            "Phone 1 Type is required."
                        ),
                    phone2: phoneValidation,
                    phone2Type: yup
                        .string()
                        .transform((value) => {
                            return value === null ? '' : value;
                        })
                        .when("phone2", {
                            is: (phone2: string) => {
                                return phone2 !== undefined
                            },
                            then: yup
                                .string()
                                .required(
                                    "You must enter Phone 2 Type when Phone 2 is entered."
                                ),
                        }),
                    email: yup
                        .string()
                        .transform((value) => {
                            return value === null ? '' : value;
                        })
                        .email("Email must be valid.")
                        .required(`Email is required`),
                })
            )
            .min(2, "t('You must have at least two contacts listed.')"),
    });

    if (showConfirmationMessage) {
        return <Row>
            <Col xs={12} lg={12}>
                <h2 className='text-primary'>Success</h2>
                <p>Your registration has been received. Please check your the entered email address for your student id.</p>
                <p>
                    <Button
                        type="button"
                        className="d-flex pt-2 flex-row align-content-center"
                        style={{backgroundColor: '#631d79', color: 'white'}}
                        variant="btn-secondary"
                        onClick={() => {
                            history.push(`/`);
                        }}
                    >
                        Return to Login
                    </Button>
                </p>
            </Col>
        </Row>;
    }

    if (communityMemberAlreadyExists) {
        return <Row className="mx-0">
            <Col xs={12}>
                <Alert variant="danger">
                    A community member account already exists. Please return to the login screen and enter your
                    community member ID with your date of birth to login,
                    or click on ‘Forgot my community member ID’ to recover your community member ID.
                </Alert>
                <Button
                    type="button"
                    className="d-flex pt-2 flex-row align-content-center"
                    style={{backgroundColor: '#631d79', color: 'white'}}
                    variant="btn-secondary"
                    onClick={() => {
                        history.push(`/`);
                    }}
                >
                    Return to Login
                </Button>
            </Col>
        </Row>;
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={async (values) => {
                setLoading(true);
                handleCreateRegistration(values);
            }}
            validateOnChange={formHasBeenSubmitted}
            validateOnBlur={formHasBeenSubmitted}
        >
            {(props) => {
                const formValues = props.values;
                const formErrors = props.errors;

                return <Form className="mt-3">
                    <Row className="mx-0 justify-content-center">
                        <Col xs={12} lg={12}>
                            <h2 className='text-primary'>Community Member Registration Form</h2>
                        </Col>
                    </Row>
                    <Row className="mx-0 mt-4 justify-content-center">
                        <Col xs={12} lg={12}>
                            <h3 className='text-primary'>Step 1: Participant Information</h3>
                        </Col>
                    </Row>
                    <Row className="mx-0">
                        <Col xs={12} lg={4}>
                            <StandardField
                                name="nameFirst"
                                type="text"
                                label="First Name"
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={4}>
                            <StandardField
                                name="nameLast"
                                type="text"
                                label="Last Name"
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={4}>
                            <StandardField
                                name="dob"
                                type="date"
                                label="Date of Birth"
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={4} className={'align-without-label'}>
                            <StandardField
                                name="grade"
                                type="select"
                                label={notice.gradeLabel}
                                handleSetFormIsModified={handleSetFormIsModified}
                                options={['Pre-K', 'K', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'Adult'].map((grade) => {
                                    return {
                                        checked: false,
                                        label: grade.toString(),
                                        value: grade.toString(),
                                    }
                                })}
                            />
                            <p>{notice.gradeDescription}</p>
                        </Col>
                        <Col xs={12} lg={4}>
                            <StandardField
                                name="gender"
                                type="select"
                                label="Gender"
                                handleSetFormIsModified={handleSetFormIsModified}
                                options={[
                                    {
                                        checked: false,
                                        label: 'Male',
                                        value: 'M',
                                    },
                                    {
                                        checked: false,
                                        label: 'Female',
                                        value: 'F',
                                    }
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row className="mx-0">
                        <Col xs={12} lg={9}>
                            <StandardField
                                name="address"
                                type="text"
                                label="Street Address"
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                    </Row>
                    <Row className="mx-0">
                        <Col xs={12} lg={4}>
                            <StandardField
                                name="city"
                                type="text"
                                label="City"
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={2}>
                            <StandardField
                                name="state"
                                type="select"
                                label="State"
                                handleSetFormIsModified={handleSetFormIsModified}
                                options={states.map((state) => {
                                    return {
                                        checked: false,
                                        label: state,
                                        value: state,
                                    }
                                })}
                            />
                        </Col>
                        <Col xs={12} lg={3}>
                            <StandardField
                                name="zip"
                                type="text"
                                label="Zip Code"
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                    </Row>
                    <Row className="mx-0">
                        <Col xs={12} lg={4}>
                            <PhoneField
                                name="homePhone"
                                label="Home Phone Number"
                                useSetFieldTouched={false}
                            />
                        </Col>
                        <Col xs={12} lg={5}>
                            <StandardField
                                name="email"
                                type="text"
                                label="Email Address"
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                    </Row>
                    <Row className="mx-0 mt-4 justify-content-center">
                        <Col xs={12} lg={12}>
                            <h3 className='text-primary'>Step 2: Parent/Guardian and Emergency Contact Information</h3>
                            <span className='text-primary'>All participants must have two contacts on file.</span>
                        </Col>
                    </Row>
                    {initialValues.guardians && <Row>
                        <Col xs={12}>
                            <GuardianForm
                                handleSetFormIsModified={handleSetFormIsModified}
                                formValues={formValues}
                                formErrors={formErrors}
                            />
                        </Col>
                    </Row>}
                    <Row className="mx-0 justify-content-center">
                        <Col xs={12} lg={12}>
                            <h3 className='text-primary'>Step 5: Waiver</h3>
                        </Col>
                    </Row>
                    <Row className="mx-0">
                        <Col xs={12} lg={12}>
                            <div>
                                By signing below, I acknowledge that all of the information contained or associated with this application is accurate and truthful. You acknowledge that by signing up and participating in this program(s), you will be waiving and releasing all claims for injuries you might sustain arising out of this program. As a participant in the program(s), I recognize and acknowledge that there are certain risks of physical injury and I agree to assume the full risk of injuries, damages or loss which I may sustain as a result of participating in any and all activities with or associated with such program(s). I agree to waive and relinquish all claims that I may have as a result of participating in the program(s) against Glenbrook High School District 225 and its officers, agents, and employees. I do hereby release and discharge the the school district and its officers, agents, and employees from any and all claims from injuries, damage, or loss which may accrue to me on account of my participation in the program(s), including transportation services and vehicle operations, when provided. I further agree to indemnify and hold harmless and defend the school district and its officers, agents, and employees from any and all claims resulting from injuries, damages, and losses sustained by me and arising out of, connected with, or in any way associated with the activities of the program(s). I have read and fully understand the above Program Details and Waiver and Release of all Claims. Participant(s) or their legal guardian must sign this Waiver.
                            </div>
                        </Col>
                    </Row>
                    <Row className="mx-0">
                        <Col xs={12} className="mt-3">
                            <StandardField
                                name="printedSignature"
                                type="text"
                                label="Parent/Guardian Name"
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                    </Row>
                    <Row className="mx-0">
                        <Col xs={12} className="mt-3">
                            <DigitalSignatureField
                                name="digitalSignature"
                                label="Signature"
                                formHasBeenSubmitted={formHasBeenSubmitted}
                            />
                        </Col>
                    </Row>
                    {!props.isValid && <Row className="mx-0">
                        <Col xs={12}>
                            <Alert variant="danger">Please fix the validation issues above</Alert>
                        </Col>
                    </Row>}
                    {communityMemberAlreadyExists && <Row className="mx-0">
                        <Col xs={12}>
                            <Alert variant="danger">
                                A community member account already exists. Please use your community member credentials,
                                or click 'forgot password' to retrieve that information.
                            </Alert>
                        </Col>
                    </Row>}
                    <Row className="mx-0">
                        <Col xs={12}>
                            <Button
                                variant={formIsModified ? (!props.isValid ? 'danger' : 'secondary') : (!props.isValid ? 'outline-danger' : 'outline-primary')}
                                type="button"
                                className="d-flex pt-2 flex-row align-content-center"
                                disabled={loading || !props.isValid}
                                onClick={() => {
                                    props.submitForm();
                                    setFormHasBeenSubmitted(true)
                                }}
                            >
                                {loading ? (
                                    <React.Fragment>
                                        <Spinner animation="border" size="sm" className="mt-1" />
                                        &nbsp; Saving
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <FontAwesomeIcon icon={faSignInAlt} size="sm" className="mt-1" />
                                        &nbsp; {formIsModified ? 'Submit' : 'Submit'}
                                    </React.Fragment>
                                )}</Button>
                        </Col>
                    </Row>
                </Form>
            }}
        </Formik>
    );
};

export default RegisterCommunityMemberForm;
