import {faSignInAlt} from "@fortawesome/free-solid-svg-icons";
import {Form, Formik, FormikValues} from "formik";
import moment from "moment";
import {useCallback, useState} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import ReactMarkdown from 'react-markdown';
import * as yup from 'yup';
import RecoverEmailModal from "../Modal/RecoverEmailModal";
import {useNotice} from "../Providers/NoticeProvider";
import FormControls from "./Fields/FormControls";
import StandardField from "./Fields/StandardField";

type Props = {
    handleSignInSubmit: (values: FormikValues) => void;
};

const today = new Date();

const parseDateString = (orginalValue: string): null | Date => {
    const date = moment(orginalValue, 'MM/DD/YYYY', true);
    return date.isValid() ? date.toDate() : null;
}

const SignInForm = ({
    handleSignInSubmit
}: Props) => {
    const [formIsModified, setFormIsModified] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showForgotCommunityMemberIdModal, setShowForgotCommunityMemberIdModal] = useState<boolean>(false);
    const [isForgotStudent, setIsForgotStudentId] = useState<boolean>(false);
    const [notice] = useNotice();

    const handleSetFormIsModified = useCallback(async (formIsModified: boolean) => {
        setFormIsModified(formIsModified);
    }, [setFormIsModified]);

    const initialValues = {
        studentId: '',
        dob: ''
    };

    const schema = yup.object({
        studentId: yup.string().label('Student ID').required(),
        dob: yup.string().label('Birth Date').test(
            'date',
            '${path} must be formatted MM/DD/YYYY',
            (value): boolean => {
                if (!value) {
                    return false;
                }
                const date = parseDateString(value);
                return date !== null && date < today;
            }).required()
    });

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={async (values) => {
                setLoading(true);
                await handleSignInSubmit(values);
                setLoading(false);
            }}
            enableReinitialize={true}
            validateOnChange={false}
        >
            {(props) => {
                return <>
                    <Form className="form-bg pt-2">
                        <Row className="mx-0 p-2">
                            <Col xs={12}>
                                <ReactMarkdown className="markdown">{notice.logInMessage}</ReactMarkdown>
                            </Col>
                            <Col xs={12}>
                                <StandardField
                                    name="studentId"
                                    type="text"
                                    label="Student or Community Member ID Number:"
                                    disabled={false}
                                    handleSetFormIsModified={handleSetFormIsModified}
                                />
                            </Col>
                            <Col xs={12}>
                                <StandardField
                                    name="dob"
                                    type="text"
                                    label="Birth Date (MM/DD/YYYY):"
                                    disabled={false}
                                    handleSetFormIsModified={handleSetFormIsModified}
                                    placeHolder="MM/DD/YYYY"
                                />
                            </Col>
                        </Row>
                        <Row className="mx-1">
                            <Col xs={12}>
                                <hr />
                            </Col>
                        </Row>
                        <Row className="mx-0 p-2">
                            <Col xs={12} md={6} className="pt-1 d-flex flex-row justify-content-start align-items-center order-md-2">
                                <div style={{height: '46px'}}>
                                    <FormControls
                                        formIsModified={formIsModified}
                                        loading={loading}
                                        submitForm={props.submitForm}
                                        showSave={true}
                                        submitType="save"
                                        disableAutoSave={true}
                                        isValid={true}
                                        saveText="Login"
                                        saveTextAction="Authenticating"
                                        saveTextPast="Login"
                                        saveIcon={faSignInAlt}
                                    />
                                </div>
                            </Col>
                            {/* <Col xs={12} md={6} className="pt-1 d-flex flex-row justify-content-center align-items-center order-md-1">
                                <Button
                                    type="button"
                                    className="d-flex pt-2 flex-row align-content-center"
                                    style={{backgroundColor: '#631d79', color: 'white'}}
                                    variant="btn-secondary"
                                    onClick={() => window.location.href = '/register-community-member'}
                                >
                                    Register Community Member
                                </Button>
                            </Col> */}
                        </Row>
                        <Row className="mx-0 p-2">
                            <Col xs={12} className="d-flex flex-row justify-content-start justify-content-between">
                                <Button
                                    type="link"
                                    className="d-flex pt-2 flex-row align-content-center"
                                    variant="link"
                                    onClick={() => {
                                        setIsForgotStudentId(true);
                                        setShowForgotCommunityMemberIdModal(true);
                                    }}
                                >
                                    Forgot Student ID Number
                                </Button>
                                <Button
                                    type="link"
                                    className="d-flex pt-2 flex-row align-content-center"
                                    variant="link"
                                    onClick={() => {
                                        setIsForgotStudentId(false);
                                        setShowForgotCommunityMemberIdModal(true);
                                    }}
                                >
                                    Forgot Community Member ID Number
                                </Button>
                                <RecoverEmailModal
                                    show={showForgotCommunityMemberIdModal}
                                    handleYes={() => setShowForgotCommunityMemberIdModal(false)}
                                    handleClose={() => setShowForgotCommunityMemberIdModal(false)}
                                    isStudent={isForgotStudent}
                                />
                            </Col>
                        </Row>
                    </Form>
                </>
            }}
        </Formik>
    );
};

export default SignInForm;
