import {faSignInAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Form, Formik, FormikValues} from "formik";
import React, {useCallback, useEffect, useState} from 'react';
import {Alert, Button, Col, Row, Spinner} from "react-bootstrap";
import * as yup from 'yup';
import {useNotice} from "../Providers/NoticeProvider";
import {isState, phoneValidation, postalCodeUS, states, yupDateRegex} from "../../Helpers/validationHelpers";
import {apiEndpoint, jwtContext} from "../Providers/JWTProvider";
import PhoneField from "./Fields/PhoneField";
import StandardField from "./Fields/StandardField";
import GuardianForm, {Guardian} from "./GuardianForm";
import CircularLoadingIndicator from "../CircularLoadingIndicator";

const schema = yup.object({
    nameFirst: yup.string().required('First Name is required'),
    nameLast: yup.string().required('Last Name is required'),
    dob: yupDateRegex.required('Date of Birth is required'),
    grade: yup.string().required('Grade is required'),
    address: yup.string().required(`Address is required`).nullable(),
    city: yup.string().required(`City is required`).nullable(),
    state: isState.required(`State is required`).nullable(),
    zip: postalCodeUS.required(`Zip is required`).nullable(),
    homePhone: phoneValidation.transform((value) => {
        return value !== '' ? value : 'null';
    }).nullable(),
    email: yup
        .string()
        .transform((value) => {
            return value === null ? '' : value;
        })
        .email("Email must be valid.")
        .required(`Email is required`),
    guardians: yup
        .array()
        .of(
            yup.object().shape({
                nameFirst: yup
                    .string()
                    .transform((value) => {
                        return value === null ? '' : value;
                    })
                    .required(
                        "First Name is required."
                    ),
                nameLast: yup
                    .string()
                    .transform((value) => {
                        return value === null ? '' : value;
                    })
                    .required(
                        "Last Name is required."
                    ),
                relationship: yup
                    .string()
                    .transform((value) => {
                        return value === null ? '' : value;
                    })
                    .required(
                        "Relationship is required."
                    ),
                relationshipOther: yup
                    .string()
                    .transform((value) => {
                        return value === null ? '' : value;
                    })
                    .when("relationship", {
                        is: (relationship: string) =>
                            relationship === "Other",
                        then: yup
                            .string()
                            .required(
                                "You must enter Relationship Other when Relationship is Other."
                            ),
                    }),
                phone1: phoneValidation
                    .required(
                        "Phone 1 is required."
                    ),
                phone1Type: yup
                    .string()
                    .transform((value) => {
                        return value === null ? '' : value;
                    })
                    .required(
                        "Phone 1 Type is required."
                    ),
                phone2: phoneValidation,
                phone2Type: yup
                    .string()
                    .transform((value) => {
                        return value === null ? '' : value;
                    })
                    .when("phone2", {
                        is: (phone2: string) => {
                            return phone2 !== undefined
                        },
                        then: yup
                            .string()
                            .required(
                                "You must enter Phone 2 Type when Phone 2 is entered."
                            ),
                    }),
                email: yup
                    .string()
                    .transform((value) => {
                        return value === null ? '' : value;
                    })
                    .email("Email must be valid.")
                    .required(`Email is required`),
            })
        )
        .min(2, "t('You must have at least two contacts listed.')"),
});

type State = {
    nameFirst: string;
    nameLast: string;
    dob: string;
    grade: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    homePhone: string;
    gender: string;
    email: string;
    guardians: Guardian[];
}

const RegisterCommunityMemberForm = () => {
    const user = React.useContext(jwtContext);
    const [notice] = useNotice();
    const [saveButtonText, setSaveButtonText] = useState<string>("Save");
    const [formIsModified, setFormIsModified] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [formHasBeenSubmitted, setFormHasBeenSubmitted] = useState(false);
    const [initialValues, setInitialValues] = useState<null|State>(null);

    useEffect(() => {
        if (!user){
            return;
        }

        const url = new URL(`/v1/profile/community`, apiEndpoint);
        user.apiFetch(url.toString()).then(async (response) => {
            setInitialValues(await response.json());
        });
    }, [user]);

    const handleSaveCM = async (values: FormikValues) => {
        if (!user){
            setLoading(false);
            return;
        }

        const url = new URL(`/v1/profile/community`, apiEndpoint);
        let init: RequestInit = {method: 'PUT'};
        init.body = JSON.stringify(values);
        const response = await user.apiFetch(url.toString(), init);

        if (response.status !== 200) {
            setLoading(false);
            return null;
        }

        setSaveButtonText('Saved');
        setLoading(false);
    };

    const handleSetFormIsModified = useCallback(async (formIsModified: boolean) => {
        setFormIsModified(formIsModified);
        if (saveButtonText === 'Saved') {
            setSaveButtonText('Save');
        }
    }, [setFormIsModified, saveButtonText]);

    if (!initialValues) {
        return <CircularLoadingIndicator/>;
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={async (values) => {
                setLoading(true);
                handleSaveCM(values);
            }}
            validateOnChange={formHasBeenSubmitted}
            validateOnBlur={formHasBeenSubmitted}
        >
            {(props) => {
                const formValues = props.values;
                const formErrors = props.errors;

                return <Form className="mt-3">
                    <Row className="mx-0 justify-content-center">
                        <Col xs={12} sm={6}>
                            <h2 className='text-primary'>Edit Profile</h2>
                        </Col>
                        <Col xs={12} sm={6} className="d-flex justify-content-sm-end">
                            <Button
                                variant={!props.isValid ? 'outline-danger' : 'outline-primary'}
                                type="button"
                                className="d-flex pt-2 flex-row align-content-center"
                                disabled={loading || !props.isValid || !formIsModified}
                                onClick={() => {
                                    props.submitForm();
                                    setFormHasBeenSubmitted(true)
                                }}
                            >
                                {loading ? (
                                    <>
                                        <Spinner animation="border" size="sm" className="mt-1" />
                                        &nbsp; Saving
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon={faSignInAlt} size="sm" className="mt-1" />
                                        &nbsp; {saveButtonText}
                                    </>
                                )}</Button>
                        </Col>
                    </Row>
                    <Row className="mx-0 mt-4 justify-content-center">
                        <Col xs={12} lg={12}>
                            <h3 className='text-primary'>Step 1: Participant Information</h3>
                        </Col>
                    </Row>
                    <Row className="mx-0">
                        <Col xs={12} lg={4}>
                            <StandardField
                                name="nameFirst"
                                type="text"
                                label="First Name"
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={4}>
                            <StandardField
                                name="nameLast"
                                type="text"
                                label="Last Name"
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={4}>
                            <StandardField
                                name="dob"
                                type="date"
                                label="Date of Birth"
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={4} >
                            <StandardField
                                name="grade"
                                type="select"
                                label={notice.gradeLabel}
                                handleSetFormIsModified={handleSetFormIsModified}
                                options={['Pre-K', 'K', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'Adult'].map((grade) => {
                                    return {
                                        checked: false,
                                        label: grade.toString(),
                                        value: grade.toString(),
                                    }
                                })}
                            />
                            <p>{notice.gradeDescription}</p>
                        </Col>
                        <Col xs={12} lg={4}>
                            <StandardField
                                name="gender"
                                type="select"
                                label="Gender"
                                handleSetFormIsModified={handleSetFormIsModified}
                                options={[
                                    {
                                        checked: false,
                                        label: 'Male',
                                        value: 'M',
                                    },
                                    {
                                        checked: false,
                                        label: 'Female',
                                        value: 'F',
                                    }
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row className="mx-0">
                        <Col xs={12} lg={9}>
                            <StandardField
                                name="address"
                                type="text"
                                label="Street Address"
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                    </Row>
                    <Row className="mx-0">
                        <Col xs={12} lg={4}>
                            <StandardField
                                name="city"
                                type="text"
                                label="City"
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={2}>
                            <StandardField
                                name="state"
                                type="select"
                                label="State"
                                handleSetFormIsModified={handleSetFormIsModified}
                                options={states.map((state) => {
                                    return {
                                        checked: false,
                                        label: state,
                                        value: state,
                                    }
                                })}
                            />
                        </Col>
                        <Col xs={12} lg={3}>
                            <StandardField
                                name="zip"
                                type="text"
                                label="Zip Code"
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                    </Row>
                    <Row className="mx-0">
                        <Col xs={12} lg={4}>
                            <PhoneField
                                name="homePhone"
                                label="Home Phone Number"
                                useSetFieldTouched={false}
                            />
                        </Col>
                        <Col xs={12} lg={5}>
                            <StandardField
                                name="email"
                                type="text"
                                label="Email Address"
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                    </Row>
                    <Row className="mx-0 mt-4 justify-content-center">
                        <Col xs={12} lg={12}>
                            <h3 className='text-primary'>Step 2:</h3>
                            <span className='text-primary'>All participants must have two contacts on file.</span>
                        </Col>
                    </Row>
                    {initialValues.guardians && <Row>
                        <Col xs={12}>
                            <GuardianForm
                                handleSetFormIsModified={handleSetFormIsModified}
                                formValues={formValues}
                                formErrors={formErrors}
                            />
                        </Col>
                    </Row>}
                    {!props.isValid && <Row className="mx-0">
                        <Col xs={12}>
                            <Alert variant="danger">Please fix the issues above</Alert>
                        </Col>
                    </Row>}
                    <Row className="mx-0">
                        <Col xs={12}>
                            <Button
                                variant={!props.isValid ? 'outline-danger' : 'outline-primary'}
                                type="button"
                                className="d-flex pt-2 flex-row align-content-center"
                                disabled={loading || !props.isValid || !formIsModified}
                                onClick={() => {
                                    props.submitForm();
                                    setFormHasBeenSubmitted(true)
                                }}
                            >
                                {loading ? (
                                    <>
                                        <Spinner animation="border" size="sm" className="mt-1" />
                                        &nbsp; Saving
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon={faSignInAlt} size="sm" className="mt-1" />
                                        &nbsp; {saveButtonText}
                                    </>
                                )}</Button>
                        </Col>
                    </Row>
                </Form>
            }}
        </Formik>
    );
};

export default RegisterCommunityMemberForm;
