import CircularLoadingIndicator from "./CircularLoadingIndicator";

const FullPageLoadingIndicator = () => {
    return (
        <div className="d-flex justify-content-center align-items-center w-100 flex-column vh-100">
            <CircularLoadingIndicator/>
        </div>
    );
}

export default FullPageLoadingIndicator;
