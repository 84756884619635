import {Field, FormikValues, useFormikContext} from 'formik';
import {FormikContextType} from "formik/dist/types";
import Form from 'react-bootstrap/Form';
import MaskedInput from "react-text-mask";

const phoneNumberMask = [
    /[1-9]/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
];

type Props = {
    label: string;
    name: string;
    arrayName?: string;
    iteration?: number;
    fieldLabelClass?: string;
    useSetFieldTouched?: boolean;
};

const PhoneField = ({
    label,
    name,
    arrayName,
    iteration,
    fieldLabelClass,
    useSetFieldTouched = true,
}: Props) => {
    const {values, handleChange, errors, setFieldTouched}: FormikContextType<FormikValues> = useFormikContext();
    const fieldName = arrayName && iteration !== undefined ? `${arrayName}[${iteration}].${name}` : name;
    const fieldValue = arrayName && iteration !== undefined ? values[arrayName][iteration][name] : values[name];

    // @ts-ignore
    const errorsName = arrayName && iteration !== undefined && errors[arrayName] && errors[arrayName][iteration] ? errors[arrayName][iteration][name] : errors[fieldName];

    return (
        <Form.Group>
            <Form.Row>
                <>
                    {label && (
                        <Form.Label column={false} className={fieldLabelClass}>{label}&nbsp;</Form.Label>
                    )}
                    <Field
                        type="text"
                        name={fieldName}
                        value={fieldValue === null ? '' : fieldValue}
                        isInvalid={!!errorsName}
                        render={({field}: {field: any;}) => (
                            <MaskedInput
                                {...field}
                                mask={phoneNumberMask}
                                placeholder="xxx-xxx-xxxx"
                                type="text"
                                onChange={async (e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={async () => {
                                    if (useSetFieldTouched && setFieldTouched) {
                                        setFieldTouched(fieldName);
                                    }
                                }}
                                className={`form-control ${errorsName ? 'is-invalid' : ''}`}
                            />
                        )}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errorsName ? errorsName : ''}
                    </Form.Control.Feedback>
                </>
            </Form.Row>
        </Form.Group>
    );
};

export default PhoneField;
