import {createRoot} from 'react-dom/client';
import './index.scss';
import App from './App';

const container = document.getElementById('root');

if (!container) {
    throw new Error('Root element missing');
}

const root = createRoot(container);

root.render((
    <App/>
));
