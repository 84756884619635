import React from 'react';
import {Form, Formik, FormikValues} from "formik";
import {Col, Row} from "react-bootstrap";
import * as yup from 'yup';
import FormControls from "./Fields/FormControls";
import StandardField from "./Fields/StandardField";
import {jwtContext} from "../Providers/JWTProvider";
import {Program, ProgramsProviderContext} from "../Providers/ProgramsProvider";

type Props = {
    setNeedsFinishSurvey: (a : boolean) => void;
    program: Program
};

const SurveyForm = ({setNeedsFinishSurvey, program} : Props) => {
    const [formIsModified, setFormIsModified] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const user = React.useContext(jwtContext)
    const [,,,answerSurvey] = React.useContext(ProgramsProviderContext);

    const handleSetFormIsModified = React.useCallback(async (formIsModified : boolean) => {
        setFormIsModified(formIsModified);
    }, [setFormIsModified]);

    const initialValues = {
        isAtRisk : '',
    };

    const studentName = user ? `${user.nameFirst}` : 'Student';

    const schema = yup.object({
        isAtRisk : yup.boolean().required(),
    });

    const handleSurveySubmit = React.useCallback(async (values: FormikValues) => {
        if (!answerSurvey) return;
        const surveyAnswers = {
            isAtRisk: values.isAtRisk
        }
        const success = await answerSurvey(surveyAnswers, program);
        if (success) {
            setNeedsFinishSurvey(false);
        }
    },[answerSurvey, program, setNeedsFinishSurvey]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={async (values) => {
                setLoading(true);
                await handleSurveySubmit(values);
                setLoading(false);
            }}
            enableReinitialize={true}
        >
            {(props) => {
                return <Form className="form-bg pt-2 container">
                    <Row>
                        <Col xs={12}>
                            <p>The majority of summer school courses will be held in-person at Glenbrook South High School.  These courses have been designated with a delivery format of "In Person".</p>
                            <p>Certain courses have been designated with a delivery format of "Online".  These courses will not meet in person, and will solely meet online using Zoom.</p>
                            <p>If <strong>{studentName}</strong> is at increased risk of severe illness or lives with someone at increased risk, you may submit a request to attend in person courses remotely.  If this situation applies to you, please indicate so below:</p>
                        </Col>
                    </Row>
                    <Row className="mx-0 p-2">
                        <Col xs={12}>
                            <StandardField
                                name="isAtRisk"
                                type="radio"
                                label=""
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                                options={[
                                        {
                                            checked: false,
                                            value: 'true',
                                            label: `${studentName} is at increased risk of severe illness or lives with someone at increased risk, and requests to attend in person courses remotely.  (Note: Documentation will be required.)`
                                        },
                                        {
                                            checked: false,
                                            value: 'false',
                                            label: `This situation does not apply for ${studentName}`
                                        },
                                    ]}
                            />
                        </Col>
                    </Row>
                    <Row className="mx-1">
                        <Col xs={12}>
                            <hr />
                        </Col>
                    </Row>
                    <Row className="mx-0 p-2">
                        <Col xs={12} className="d-flex flex-row justify-content-start justify-content-lg-end">
                            <FormControls
                                formIsModified={formIsModified}
                                loading={loading}
                                submitForm={props.submitForm}
                                showSave={true}
                                submitType="save"
                                disableAutoSave={true}
                                isValid={props.isValid}
                                saveText="Save"
                                saveTextAction="Saving"
                                saveTextPast="Save"
                            />
                        </Col>
                    </Row>
                </Form>
            }}
        </Formik>
    );
};

export default SurveyForm;
