import NavBase from './NavBase';
import {faAddressBook, faInfoCircle, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Nav, Navbar} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {signOutInstructor} from "../Providers/JWTProvider";

type Props = {
    showNav ?: boolean;
    showInstructionsModal : () => void;
}

const InstructorHeader = ({
    showNav,
    showInstructionsModal,

} : Props) => {
    let history = useHistory();

    return (
        <NavBase>
            <div className="text-right d-flex flex-row">
                <Navbar.Toggle aria-controls="chris-nav" />
                {showNav && (
                    <Navbar.Collapse id="glenbrook-nav" className="">
                        <Nav className="mr-auto">
                            <Nav.Link
                                className="d-flex flex-column align-items-center menu-item px-2 pt-3"
                                onClick={() => {
                                    history.push(`/instructor`);
                                }}
                            >
                                <span className="align-items-center flex-column d-flex">
                                    <FontAwesomeIcon icon={faAddressBook} size="lg" className="cursor-pointer" />
                                    <span className="p-2 text-label">My Activities</span>
                                </span>
                            </Nav.Link>
                            <Nav.Link
                                className="d-flex flex-column align-items-center menu-item mx-2 pt-3 px-3 menu-item-border-right"
                                onClick={() => {
                                    showInstructionsModal();
                                }}
                            >
                                <span className="align-items-center flex-column d-flex">
                                    <FontAwesomeIcon icon={faInfoCircle} size="lg" className="cursor-pointer" />
                                    <span className="p-2 text-label">Instructions</span>
                                </span>
                            </Nav.Link>
                            <Nav.Link
                                className="d-flex flex-column align-items-center menu-item pt-3 px-2"
                                onClick={() => {
                                    signOutInstructor();
                                    window.location.reload();
                                }}
                            >
                        <span className="align-items-center flex-column d-flex">
                             <FontAwesomeIcon icon={faSignOutAlt} size="lg" className="cursor-pointer" />
                            <span className="p-2 text-label">Sign Out</span>
                        </span>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                )}
            </div>
        </NavBase>
    );
};

export default InstructorHeader;
