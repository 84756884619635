import {FormikValues, useFormikContext} from 'formik';
import {FormikContextType} from "formik/dist/types";
import {useEffect, useRef, useState} from 'react';
import Form from 'react-bootstrap/Form';
import SignatureCanvas from 'react-signature-canvas';
// import {FormControlContext} from '../../Providers/FormControlContext';

export type DigitalSignatureFieldOption = {
    label: string;
    value: string;
    checked: boolean;
};

type Props = {
    name: string;
    label: string;
    formHasBeenSubmitted: boolean;
    disabled?: boolean;
    arrayName?: string;
    iteration?: number;
    className?: string;
    fieldLabelClass?: string;
    errorNoticeOverride?: string;
};

const DigitalSignatureField = ({
    name,
    label,
    formHasBeenSubmitted,
    disabled,
    arrayName,
    iteration,
    className,
    fieldLabelClass,
    errorNoticeOverride
}: Props) => {
    const {values, errors, setFieldValue}: FormikContextType<FormikValues> = useFormikContext();
    // const {setFieldModified} = useContext(FormControlContext);
    const fieldName = arrayName && iteration !== undefined ? `${arrayName}[${iteration}].${name}` : name;
    const fieldValue = arrayName && iteration !== undefined ? values[arrayName][iteration][name] : values[name];
    // @ts-ignore
    const errorsName = arrayName && iteration !== undefined && errors[arrayName] && errors[arrayName][iteration] ? errors[arrayName][iteration][name] : errors[fieldName];
    const [signatureError, setSignatureError] = useState<boolean>(false);
    const sigRef = useRef<any>(null);

    const onEndSigning = (): void => {
        if (sigRef.current !== undefined) {
            if (sigRef.current.isEmpty()) {
                setSignatureError(true);
                return;
            }

            setSignatureError(false);
            const signature = sigRef.current.toDataURL('image/png');
            setFieldValue(fieldName, signature, formHasBeenSubmitted);
            // setFieldModified();
        }
    };

    const clearSignature = (): void => {
        if (sigRef.current !== undefined) {
            sigRef.current.clear();
            setFieldValue(fieldName, '', true);
            // setFieldModified();
        }
    }

    useEffect(() => {
        if (sigRef.current !== undefined && sigRef.current.isEmpty() && fieldValue !== undefined) {
            sigRef.current.fromDataURL(fieldValue)
            return;
        }
    }, [fieldValue]);

    return (
        <Form.Group>
            <Form.Row>
                {label && (
                    <Form.Label column={false} className={fieldLabelClass}>{label}&nbsp;</Form.Label>
                )}
            </Form.Row>
            <Form.Row>
                <div>
                    <SignatureCanvas
                        penColor='black'
                        canvasProps={{width: '300px', height: 200, className: 'sigCanvas'}}
                        ref={sigRef}
                        onEnd={onEndSigning}
                    />
                </div>
            </Form.Row>
            <Form.Row>
                <Form.Control
                    type="hidden"
                    name={fieldName}
                    value={fieldValue === null ? '' : fieldValue}
                    isInvalid={!!errorsName || !!errorNoticeOverride}
                    disabled={disabled}
                    className={className ? className : ''}
                />
                <Form.Control.Feedback type="invalid">
                    {errorsName ? errorsName : (errorNoticeOverride ? errorNoticeOverride : '')}
                </Form.Control.Feedback>
                <div className="d-flex flex-row pl-4 pr-4 justify-content-beginning">
                    {signatureError && <p className="text-danger w-75">Add</p>}
                    <div className="text-primary cursor-pointer">
                        <p onClick={clearSignature}>Clear</p>
                    </div>
                </div>
            </Form.Row>
        </Form.Group>
    );
};

export default DigitalSignatureField;