import {useContext, useState} from 'react';
import {Container} from "react-bootstrap";
import {InstructorProgramsProviderContext} from "../../components/Providers/InstructorProgramsProvider";
import InstructorHeader from "../../components/Navigation/InstructorHeader";
import InstructorInstructionsModal from "../../components/Modal/InstructorInstructionsModal";
import ProgramSelection from "../../components/Program/ProgramSelection";
import {useHistory} from "react-router-dom";

const InstructorProgramList = () => {
    const [showInstructorInstructionsModal, setShowInstructorInstructionsModal] = useState(true);
    const context = useContext(InstructorProgramsProviderContext);
    const history = useHistory();

    const availablePrograms = context.programs.map(p => p.programName)


    return <>
        <InstructorHeader
            showNav={true}
            showInstructionsModal={() => setShowInstructorInstructionsModal(true)}
        />
        <Container className="pt-5">
            <ProgramSelection
                availablePrograms={availablePrograms}
                loading={false}
                setActiveProgram={(name) => {
                    const program = context.programs.find(p => p.programName === name);
                    if (program) {
                        history.push(`/instructor/program/${program.id}`);
                    }
                }}
            />
        </Container>
        <InstructorInstructionsModal
            show={showInstructorInstructionsModal}
            handleClose={() => setShowInstructorInstructionsModal(false)}
        />
    </>
}

export default InstructorProgramList;
