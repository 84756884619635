import {useEffect} from 'react';
import {signOut} from "../components/Providers/JWTProvider";
import FullPageLoadingIndicator from "../components/FullPageLoadingIndicator";

const SignOut = () => {

    useEffect( () => {
        signOut();
        window.location.href = '/';
    }, []);

    return <FullPageLoadingIndicator/>;
};

export default SignOut;
