import {useElements, useStripe} from "@stripe/react-stripe-js";
import {Formik, FormikValues} from "formik";
import React, {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from "react";
import {Alert, Col, Form, Row} from "react-bootstrap";
import * as yup from 'yup';
import StandardField from "./Fields/StandardField.tsx";
import LocalStorageTimer from "../LocalStorageTimer.tsx";
import {apiEndpoint, jwtContext} from "../Providers/JWTProvider.tsx";
import {
    ProgramCourse,
    ProgramsProviderContext,
    STATUS_PARTIAL,
    STATUS_WAITLISTED
} from "../Providers/ProgramsProvider.tsx";
import {ErrorBoundary, FallbackProps} from 'react-error-boundary'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import PaymentFormPaymentAmount from "@/components/Forms/Fields/PaymentFormPaymentAmount.tsx";
import {formatter} from "@/Helpers/formatter.ts";
import {buildPayAmount} from "@/components/Forms/CartPaymentForm.tsx";
import {Box, Button, CircularProgress, Grid, Tooltip} from "@mui/material";
import StripeForm from "@/components/StripeForm.tsx";
import useConfirmMailIn from "@/hooks/useConfirmMailIn.tsx";
import ButtonCollection from "@/components/Forms/Fields/ButtonCollection.tsx";

type Props = {
    course: ProgramCourse;
    programName: string;
    handleClose: (showCancelModal?: boolean, showConfirmModal?: boolean) => void;
    handleLocalCancelOrClose: () => Promise<void>;
    setConfirmationId: (confirmationId: string) => void;
    switchPayment?: boolean;
    courseHasCreditsApplied: boolean;
    show : boolean;
    cancellationPending : boolean;
    setCancellationPending : Dispatch<SetStateAction<boolean>>
};

const ErrorFallback = ({error , resetErrorBoundary} : FallbackProps) => {
    return (
        <div role="alert">
            <p>There was a issue loading the credit card payments</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    )
};

const PaymentForm = (
    {
        course,
        programName,
        handleClose,
        handleLocalCancelOrClose,
        setConfirmationId,
        switchPayment,
        courseHasCreditsApplied,
        show,
        cancellationPending,
        setCancellationPending
    } : Props) => {
    const stripe = useStripe();
    const {addCartItem} = useCartContext();
    const elements = useElements();
    const user = React.useContext(jwtContext);
    const [, performRegistrationAction] = React.useContext(ProgramsProviderContext);
    const resetRegistrationStatus = React.useContext(ProgramsProviderContext)[5];
    const [isProcessing, setIsProcessing] = useState(false);
    const [showPayNow, setShowPayNow] = useState<boolean>(false);
    const [submitStripeValues, setSubmitStripeValues] = useState<FormikValues | null>(null);
    const [stripeErrorMessage, setStripeErrorMessage] = useState('');
    const confirmMailIn = useConfirmMailIn();

    const freeMode = course.financialAid === 'free' && course.cost === 0;

    useEffect(() => {
        if (freeMode) {setShowPayNow(true)}
    }, [freeMode]);

    const courseCostIsZero = course.cost === 0;
    const waitlistMode = course.status === STATUS_WAITLISTED;

    const timeout = useMemo(() : string | null => {
        const localStorageItemString = localStorage.getItem('registration-course-' + course.courseSelectionUUID);
        const localStorageItem = localStorageItemString ? JSON.parse(localStorageItemString) : null;
        return localStorageItem && 'timeout' in localStorageItem && typeof localStorageItem.timeout === 'string'
            ? localStorageItem.timeout satisfies string : null;
    }, [course, show]);

    useEffect(() => {
        setIsProcessing(false);
        setCancellationPending(false);
    }, [show]);

    const confirmFab = async () => {
        const url = new URL(`/v1/payment/confirm-fab`, apiEndpoint);
        let init: RequestInit = {method: 'POST'};
        init.body = JSON.stringify({
            courseSectionId: course.courseSelectionUUID,
            studentId: user?.studentId,
        });

        if (resetRegistrationStatus && switchPayment) {
            await resetRegistrationStatus(course);
        }

        return await user?.apiFetch(url.toString(), init);
    };

    const confirmFreeAfterCreditsApplied = async () => {
        const url = new URL(`/v1/payment/confirm-free-after-cc`, apiEndpoint);
        let init : RequestInit = {method: 'POST'};
        init.body = JSON.stringify({
            courseSectionId: course.courseSelectionUUID,
            studentId: user?.studentId,
        });

        return await user?.apiFetch(url.toString(), init);
    };

    const confirmWaitlist = async () => {
        const url = new URL(`/v1/payment/confirm-waitlist`, apiEndpoint);
        let init: RequestInit = {method: 'POST'};
        init.body = JSON.stringify({
            courseSectionId: course.courseSelectionUUID,
            studentId: user?.studentId,
        });

        return await user?.apiFetch(url.toString(), init);
    };

    const handleMailInSubmit = async () => {
        setIsProcessing(true);

        if (performRegistrationAction) {
            const confirmResponse = await confirmMailIn([course.courseSelectionUUID]);

            if (confirmResponse?.ok) {
                const responseJson = await confirmResponse.json();
                setConfirmationId(responseJson.confirmationIds[0]);

                if (courseHasCreditsApplied) {
                    await performRegistrationAction(course, programName);
                }
                if (resetRegistrationStatus && switchPayment) {
                    await resetRegistrationStatus(course);
                }
                localStorage.removeItem('registration-course-' + course.courseSelectionUUID);
            }
        }

        handleClose(false, true);
        setIsProcessing(false);
    };

    const handleFabSubmit = async () => {
        setIsProcessing(true);

        if (performRegistrationAction) {
            const confirmResponse = await confirmFab();

            if (confirmResponse?.ok) {
                const responseJson = await confirmResponse.json()
                setConfirmationId(responseJson.confirmationId);

                if (courseHasCreditsApplied) {
                    await performRegistrationAction(course, programName);
                }
                localStorage.removeItem('registration-course-' + course.courseSelectionUUID)
            }
        }

        handleClose(false, true);
        setIsProcessing(false);
    };

    const handleFreeAfterCourseCredits = async () => {
        setIsProcessing(true);

        if (performRegistrationAction) {
            const confirmResponse = await confirmFreeAfterCreditsApplied();

            if (confirmResponse?.ok) {
                const responseJson = await confirmResponse.json()
                setConfirmationId(responseJson.confirmationId);
                if (courseHasCreditsApplied) {
                    await performRegistrationAction(course, programName);
                }
                localStorage.removeItem('registration-course-' + course.courseSelectionUUID)
            }
        }

        handleClose(false, true);
        setIsProcessing(false);
    }

    const handleWaitlistSubmit = async () => {
        setIsProcessing(true);

        if (performRegistrationAction) {
            const confirmResponse = await confirmWaitlist();

            if (confirmResponse?.ok) {
                const responseJson = await confirmResponse.json()
                setConfirmationId(responseJson.confirmationId);
                localStorage.removeItem('registration-course-' + course.courseSelectionUUID)
            }
        }

        handleClose(false, true);
        setIsProcessing(false);
    };

    const handleSetFormIsModified = useCallback(async () => {
    }, []);

    const cancelRegistration = async (showCancelModal: boolean, reason : string) => {
        if (performRegistrationAction) {
            // cancel registration
            await performRegistrationAction(course, programName, reason);
        }

        localStorage.removeItem('registration-course-' + course.courseSelectionUUID);
        setCancellationPending(false);
        handleClose(showCancelModal);
    }

    const timeOutCancelRegistration = async () => {
        await cancelRegistration(true, 'Payment Timeout');
    }

    const initialValues = {
        paymentMethod: waitlistMode ? 'waitlist' : (freeMode ? 'fab' : ''),
        paymentType: course.programAllowPartial ? '' : 'full',
        partialPaymentAmount: '',
    }

    const schema = yup.object({
        paymentMethod: yup.string().required('Payment Method is a required field.'),
        paymentType: yup.string().when('paymentMethod', {
            is: (val : string) => val !== 'fab',
            then: yup.string().required('Payment Type is a required field.')
        }),
        partialPaymentAmount: yup.number()
            .when('paymentType', {
                is: 'partial',
                then: yup.number()
                    .min(course.cost / 10, `Payment amount must be between at least 10% of the total cost (${formatter.format(course.cost / 10)}).`)
                    .max(course.cost, `Max payment of (${formatter.format(course.cost)}).`)
                    .required(`Payment minimum is 10% of the total cost (${formatter.format(course.cost / 10)}).`)
                    .test(
                        "maxDigitsAfterDecimal",
                        "Payment must have 2 digits after decimal or less",
                        (number) => number ? /^\d+(\.\d{1,2})?$/.test(number.toString()) : false
                    ),
                otherwise: yup.number().notRequired(),
            }),
    });

    const cost = course.cost - course.paidAmount;
    const displayCost = formatter.format(cost);

    useEffect(() => {
        if (stripeErrorMessage !== '') {
            setIsProcessing(false);
            setSubmitStripeValues(null);
        }
    }, [stripeErrorMessage]);

    return (
        <>
            {timeout && (<Row className="chris-form-bg mx-0 py-1 px-2">
                <Col xs={12} style={{color: 'red', textAlign: 'right'}}>
                    You have <LocalStorageTimer timeout={timeout} callback={timeOutCancelRegistration} /> to Complete this step.
                </Col>
            </Row>)}
            <Row className="chris-form-bg mx-0 py-1 px-2">
                <Col xs={12}>
                    All fees must be paid in full by the due date for this activity. Any registrations with outstanding fees after the due date will be cancelled.
                </Col>
            </Row>
            <Row className="chris-form-bg mx-0 py-1 px-2">
                <Col xs={12}>
                    <div style={{textDecoration: 'underline', fontWeight: 800, fontSize: '120%'}}>Activity</div>
                    <div>{user?.nameFirst + ' ' + user?.nameLast + ' - ' + user?.studentId}</div>
                    <div>{course.name + ', Section ' + course.sectionNumber}</div>
                </Col>
            </Row>
            <Row className="chris-form-bg mx-0 py-1 px-2">
                <Col xs={12}>
                    <div className="mt-2 mb-2">Cost: {displayCost}</div>
                </Col>
            </Row>
            {!cancellationPending && <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={async (values) => {
                    setStripeErrorMessage('');
                    if (values.paymentMethod === 'stripe') {
                        setSubmitStripeValues(values);
                    } else if (values.paymentMethod === 'mail') {
                        await handleMailInSubmit();
                    } else if (values.paymentMethod === 'fab') {
                        await handleFabSubmit();
                    } else if (values.paymentMethod === 'freeAfterCourseCreditsApplied') {
                        await handleFreeAfterCourseCredits();
                    } else if (values.paymentMethod === 'waitlist') {
                        await handleWaitlistSubmit();
                    }
                }}
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize={true}
            >
                {(props) => {
                    const formValues = props.values;
                    const submitForm = props.submitForm;

                    const payNowAmount = buildPayAmount(
                        formValues.paymentType,
                        course.cost - course.paidAmount,
                        formValues.partialPaymentAmount
                    );

                    const isPayButtonDisabled = !freeMode && (!formValues.paymentType || (formValues.paymentType === 'partial' && (!formValues.partialPaymentAmount) || isNaN(+formValues.partialPaymentAmount)));

                    return <Form.Group>
                        {waitlistMode && <Row className="chris-form-bg ml-2 pt-3">
                            <Col xs={12}>
                                <div style={{textDecoration: 'underline', fontWeight: 800, fontSize: '120%'}}>Payment Options</div>
                                <p>The activity you requested is currently full. You will be added to the waitlist.</p>
                                <StandardField
                                    name="paymentMethod"
                                    type="hidden"
                                    label=""
                                    disabled={false}
                                    handleSetFormIsModified={(handleSetFormIsModified)}
                                />
                            </Col>
                        </Row>}
                        {courseCostIsZero && !waitlistMode && <Row className="chris-form-bg ml-2 pt-3">
                            <Col xs={12}>
                                <div style={{textDecoration: 'underline', fontWeight: 800, fontSize: '120%'}}>Payment Options</div>
                                <div className="mt-2 mb-2">
                                    Cost: {displayCost}&nbsp;
                                    {course.courseCredits && (
                                        <div style={{display: 'inline'}}>
                                                <span style={{textDecoration: 'line-through', color: 'red'}}>
                                                        {formatter.format(course.originalCost)}
                                                </span>
                                            <div style={{display: 'inline', color: 'green'}}>&nbsp;Course credits applied!</div>
                                        </div>
                                    )}
                                </div>
                                {freeMode ? <StandardField
                                        name="paymentMethod"
                                        type="radio"
                                        label=""
                                        disabled={false}
                                        handleSetFormIsModified={(handleSetFormIsModified)}
                                        options={[
                                            {
                                                checked: true,
                                                value: 'fab',
                                                label: `Financial Assistance Benefit`
                                            },
                                        ]}
                                    />
                                    : <StandardField
                                        name="paymentMethod"
                                        type="radio"
                                        label=""
                                        disabled={false}
                                        handleSetFormIsModified={(handleSetFormIsModified)}
                                        options={[
                                            {
                                                checked: true,
                                                value: 'freeAfterCourseCreditsApplied',
                                                label: `No Payment needed`
                                            },
                                        ]}
                                    />}
                            </Col>
                        </Row>}
                        {!courseCostIsZero && !waitlistMode && showPayNow && <>
                            <Row className="chris-form-bg ml-2 py-3">
                                <Col xs={12}>
                                    <div style={{textDecoration: 'underline', fontWeight: 800, fontSize: '120%'}}>Payment Options</div>
                                    <div className="mt-2">
                                        Cost: {displayCost}&nbsp;
                                        {course.courseCredits && (
                                            <div style={{display: 'inline'}}>
                                                <span style={{textDecoration: 'line-through', color: 'red'}}>
                                                        {formatter.format(course.originalCost)}
                                                </span>
                                                <div style={{display: 'inline', color: 'green'}}>&nbsp;Course credits applied!</div>
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                            {course.programAllowPartial && <Row className="chris-form-bg ml-2 ">
                                <Col xs={12}>
                                    <ButtonCollection
                                        name="paymentType"
                                        options={[
                                            {
                                                value: 'full',
                                                label: `Pay ${displayCost} in Full`
                                            },
                                            {
                                                value: 'partial',
                                                label: 'Partial Payment',
                                                disabled: cost < .5 || cost < (course.cost * .1)
                                            }
                                        ]}
                                    />
                                </Col>
                            </Row>}
                            {formValues.paymentType === 'partial' && <Row className="chris-form-bg ml-2 pt-3">
                                <Col xs={12}>
                                    <PaymentFormPaymentAmount name="partialPaymentAmount"/>
                                </Col>
                            </Row>}
                            <Row className="chris-form-bg ml-2 py-3">
                                <Col xs={12}>
                                    <ButtonCollection
                                        name="paymentMethod"
                                        options={[
                                            {
                                                value: 'mail',
                                                label: 'Mail a Paper Check',
                                                disabled: !formValues.paymentType || formValues.paymentType === 'partial',
                                                hidden: formValues.paymentType === 'partial'
                                            },
                                            {
                                                value: 'stripe',
                                                label: 'Pay Online',
                                                disabled: !formValues.paymentType || cost < .5,
                                                selected: formValues.paymentType === 'partial'
                                            }
                                        ]}
                                        sx={{
                                            display: formValues.paymentType === 'partial' ? 'none' : 'block',
                                            mt: 1,
                                            mb: 2,
                                        }}
                                    />
                                </Col>
                            </Row>

                            {formValues.paymentMethod === 'mail' && <Row>
                                <Col xs={12}>
                                    <Alert variant="notice" className="m-0 pt-0 ml-4">
                                        <div>Submit all checks to:</div>
                                        <div>Glenbrook High School District 225</div>
                                        <div>ATTN: Enrollment Specialist</div>
                                        <div>3801 W Lake Ave.</div>
                                        <div>Glenview, IL  60026</div>
                                        <div>BE SURE TO INDICATE THE PARTICIPANT'S NAME <span style={{textDecoration: 'underline'}}>AND</span> ID NUMBER</div>
                                    </Alert>
                                </Col>
                            </Row>}
                            {(formValues.paymentMethod === 'stripe' || formValues.paymentType === 'partial') && <Grid container>
                                <Grid item xs={12}>
                                    {(stripe === null || elements === null) ?  <p>Loading credit card payment form. If this message persists, try reloading the page or clearing your browser cache.</p> :
                                        <ErrorBoundary
                                            FallbackComponent={ErrorFallback}
                                            onReset={() => {
                                                formValues.paymentMethod = '';
                                            }}
                                        >
                                            <StripeForm
                                                cartId={null}
                                                courseSectionId={course.courseSelectionUUID}
                                                submitValues={submitStripeValues}
                                                payNowAmount={payNowAmount}
                                                setIsProcessing={setIsProcessing}
                                                handleModalClose={handleClose}
                                                stripeErrorMessage={stripeErrorMessage}
                                                setStripeErrorMessage={setStripeErrorMessage}
                                            />
                                        </ErrorBoundary>}
                                </Grid>
                            </Grid>}
                        </>}
                        <Col xs={12}><hr /></Col>
                        {!cancellationPending && <Row className="mx-0 mt-2">
                            <Col xs={12} className="d-flex flex-row justify-content-space-between justify-content-lg-end">
                                {!isProcessing && <Button
                                    sx={{
                                        backgroundColor: '#6c757d',
                                        color: '#fff',
                                        borderRadius: '8px',
                                        fontWeight: 800,
                                        '&:hover': {
                                            backgroundColor: '#6c757d',
                                        },
                                        mr: 1,
                                    }}
                                    onClick={handleLocalCancelOrClose}
                                >Cancel</Button>}
                                {!freeMode && !isProcessing && <Button
                                    sx={{
                                        backgroundColor: '#631d79',
                                        color: '#fff',
                                        borderRadius: '8px',
                                        fontWeight: 800,
                                        '&:hover': {
                                            backgroundColor: '#631d79',
                                        },
                                        mr: 1,
                                    }}
                                    onClick={() => {
                                        addCartItem(course.courseSelectionUUID, course.status !== STATUS_PARTIAL);
                                        handleClose(false, false);
                                    }}
                                >
                                    <ShoppingCartIcon/>
                                    Add to Cart
                                </Button>}
                                {!showPayNow && <Button
                                    sx={{
                                        backgroundColor: '#631d79',
                                        color: '#fff',
                                        borderRadius: '8px',
                                        fontWeight: 800,
                                        '&:hover': {
                                            backgroundColor: '#631d79',
                                        },
                                        mr: 1,
                                    }}
                                    className="d-flex pt-2 flex-row align-content-center mr-2"
                                    onClick={() => setShowPayNow(true)}
                                >
                                    Pay Now {formatter.format(payNowAmount)}
                                </Button>}
                                {!isProcessing && showPayNow && <Tooltip title={isPayButtonDisabled ? 'Please fix the validation issues above' : ''}>
                                    <Button
                                        sx={{
                                            backgroundColor: '#631d79',
                                            color: '#fff',
                                            borderRadius: '8px',
                                            fontWeight: 800,
                                            '&:hover': {
                                                backgroundColor: '#631d79',
                                            },
                                        }}
                                        // className="d-flex pt-2 flex-row align-content-center mr-2"
                                        disabled={isPayButtonDisabled}
                                        onClick={submitForm}
                                    >
                                        <Box sx={{color: '#fff'}}>
                                            {waitlistMode ? 'Join Waitlist' : `Pay ${isNaN(payNowAmount) ? '' : formatter.format(payNowAmount)} Now`}
                                        </Box>
                                    </Button>
                                </Tooltip>}

                                {isProcessing && <Button
                                    sx={{
                                        backgroundColor: '#631d79',
                                        color: '#fff',
                                        borderRadius: '8px',
                                        fontWeight: 800,
                                        '&:hover': {
                                            backgroundColor: '#631d79',
                                        },
                                    }}
                                    // className="d-flex pt-2 flex-row align-content-center mr-2"
                                    disabled={isPayButtonDisabled}
                                    onClick={submitForm}
                                >
                                    <CircularProgress
                                        size='1rem'
                                        sx={{
                                            mr: 1,
                                            color: '#fff',
                                        }}
                                    />
                                    <Box sx={{color: '#fff'}}>{`Processing Payment`}</Box>
                                </Button>}
                            </Col>
                        </Row>}
                    </Form.Group>
                }}
            </Formik>}
            {cancellationPending && <>
                <Row className="mx-0 mt-2">
                    <Col xs={12} className="d-flex flex-row justify-content-space-between justify-content-lg-end">
                        <Alert variant="warning">Closing this window before payment is provided will cancel your current registration. Do you wish to continue?</Alert>
                    </Col>
                </Row>
                <Row className="mx-0 mt-2">
                    <Col xs={12} className="d-flex flex-row justify-content-space-between justify-content-lg-end">
                        <Button
                            sx={{
                                backgroundColor: '#6c757d',
                                color: '#fff',
                                borderRadius: '8px',
                                fontWeight: 800,
                                '&:hover': {
                                    backgroundColor: '#6c757d',
                                },
                                mr: 1,
                            }}
                            onClick={() => setCancellationPending(false)}
                        >No, Provide Payment</Button>
                        <Button
                            sx={{
                                backgroundColor: '#631d79',
                                color: '#fff',
                                borderRadius: '8px',
                                fontWeight: 800,
                                '&:hover': {
                                    backgroundColor: '#631d79',
                                },
                                mr: 1,
                            }}
                            onClick={handleLocalCancelOrClose}
                        >Yes, Cancel Registration</Button>
                    </Col>
                </Row>
            </>}
        </>
    );
}

export default PaymentForm;
