import React from 'react';
import GeneralRegisterButton, {
    GeneralRegisterButtonProps
} from "@/components/Program/RegisterButtons/GeneralRegisterButton.tsx";

const RemoveFromWaitListButton = (props : GeneralRegisterButtonProps): React.ReactElement => (
    <GeneralRegisterButton
        buttonText="Remove From Waitlist"
        buttonTextAction="Removing"
        {...props}
    />
);

export default RemoveFromWaitListButton;
