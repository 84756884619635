import React from 'react';
import GeneralRegisterButton, {
    GeneralRegisterButtonProps
} from "@/components/Program/RegisterButtons/GeneralRegisterButton.tsx";

const RegisteredAddPayment = (props : GeneralRegisterButtonProps): React.ReactElement => (
    <GeneralRegisterButton
        buttonText="You are registered - Click to add payment"
        buttonTextAction="Registering"
        {...props}
    />
);

export default RegisteredAddPayment;
