import moment from "moment";
import * as yup from "yup";

export const phoneValidation = yup
    .string()
    .transform((value) => {
        return value === '' ? null : value;
    })
    .nullable()
    .matches(
        /[0-9]{3}-[0-9]{3}-[0-9]{4}/,
        'Phone numbers must be entered in the following format: 555-555-5555'
    );

export const yupDateRegex = yup
    .string()
    .transform((value) => {
        if (value === null || value === '') {
            return null;
        }

        if (moment(value, 'MM/DD/YYYY', true).isValid()) {
            return moment(value).format('MM/DD/YYYY');
        }

        if (moment(value, 'YYYY-MM-DD', true).isValid()) {
            return moment(value).format('MM/DD/YYYY');
        }

        return null;
    })
    .nullable()
    .matches(
        /^\d{2}\/\d{2}\/\d{4}$/,
        'Invalid date format. Use MM/DD/YYYY.'
    );

export const postalCodeUS = yup.string().matches(
    /^\d{5}([ -]\d{4})?/,
    'Invalid postal code.'
);

export const states = ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming",];

export const isState = yup.string().oneOf(
    states,
    'Invalid state.'
)
