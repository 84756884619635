import {Button, Col, Row} from "react-bootstrap";
import ModalBase from "./ModalBase";

type Props = {
    show: boolean;
    confirmationNumber: string;
    handleClose: () => void;
    confirmationMessage: string;
    waitlistMessage: string;
    isWaitlist?: boolean;
    isPreRegistered?: boolean;
};

const RegistrationConfirmed = ({
    show,
    confirmationNumber,
    handleClose,
    confirmationMessage,
    waitlistMessage,
    isWaitlist,
    isPreRegistered
}: Props) => {

    let confirmedContext = isWaitlist ? 'Waitlist' : 'Registration';
    if (isPreRegistered) {
        confirmedContext = 'Payment';
    }

    return (
        <ModalBase
            show={show}
            title={confirmedContext + " Confirmed"}
            size="lg"
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={handleClose}
        >
            {!isWaitlist && <Row className="chris-form-bg mx-0 py-3 px-2" style={{whiteSpace: "pre-wrap"}}>
                <Col xs={12}>{confirmationMessage.replace('{confirmationNumber}', confirmationNumber)}</Col>
            </Row>}
            {isWaitlist && <Row className="chris-form-bg mx-0 py-3 px-2" style={{whiteSpace: "pre-wrap"}}>
                <Col xs={12}>{waitlistMessage.replace('{confirmationNumber}', confirmationNumber)}</Col>
            </Row>}
            <Col xs={12}><hr /></Col>
            <Row className="mx-0 mt-2">
                <Col xs={12} className="d-flex flex-row justify-content-start justify-content-lg-end">
                    <Button
                        variant="secondary"
                        type="button"
                        className="d-flex pt-2 flex-row align-content-center mr-2"
                        onClick={async () => {
                            handleClose();
                        }}
                    >Close</Button>
                </Col>
            </Row>
        </ModalBase>);
}

export default RegistrationConfirmed;
