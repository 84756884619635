import React, {ReactNode, useContext, useEffect} from 'react';
import {apiEndpoint, ApiFetch, jwtInstructorContext,} from "./JWTProvider";
import {useHistory, useLocation} from "react-router-dom";

export type InstructorProgram = {
    active : boolean;
    courseSelections : Array<InstructorProgramCourseSelection>;
    id : string;
    oneSectionPerTerm : boolean;
    programCancellationMessage : string;
    programName : string;
    requiresPayment : boolean | null;
};

export type InstructorProgramCourseSelection = {
    id : string;
    instructorName : string;
    facultyID : string;
    sectionNumber : number;
    meetingDateStart : string | null;
    meetingTimeStart : string | null;
    meetingDateEnd : string | null;
    meetingTimeEnd : string | null;
    meetingDaysOfWeek : string | null;
    deliveryMethod : string;
    roomNumber : string;
    capacity : number,
    creditHours : number,
    cost : number,
    restrictToSchool : string | null,
    allowCommunity : number | null,
    requiresPayment : string | null,
    course : InstructorProgramCourse;
    registrations : Array<InstructorProgramRegistration>;
    school : InstructorProgramSchool;
    sectionTerms: Array<InstructorSectionTerm>;
};

export type InstructorProgramCourse = {
    id : string;
    courseName : string;
    courseNumber : string;
    courseDescription : string | null;
    subject : string;
    gradeLevelRequirement : string;
    gender : string | null;
    activityType : string;
    courseNumberName : string;
    allowWaitlist : string | null;
    createdAt : string;
    updatedAt : string;
    oneSectionPerTermOverride : string | null;
    price : string | null;
    registrationQuestionEnabled : string | null;
};

export type InstructorProgramRegistration = {
    id : string;
    createdAt : string;
    status : string | null;
    student : InstructorRosterStudent | null;
    communityMember : InstructorRosterCommunityMember | null;
    courseAnswer : InstructorCourseAnswer[];
    amountDue : number;
};

export type InstructorCourseAnswer = {
    id : string;
    cachedQuestion : string;
    answer : string;
}

export type InstructorRosterStudent = {
    id : number;
    nameFirst : string;
    nameLast : string;
    grade : number;
    dob : string;
    gender : string;
    custodianEmail1 : string | null;
    custodianEmail2 : string | null;
    custodianEmail3 : string | null;
    custodianEmail4 : string | null;
    custodianName1 : string | null;
    custodianName2 : string | null;
    custodianName3 : string | null;
    custodianName4 : string | null;
    emergencyPhone1 : string | null;
    emergencyName1 : string | null;
    emergencyRelationship1 : string | null;
    emergencyPhone2 : string | null;
    emergencyName2 : string | null;
    emergencyPhone3 : string | null;
    emergencyName3 : string | null;
    emergencyName4 : string | null;
    emergencyPhone4 : string | null;
};

export type InstructorRosterCommunityMember = {
    id : string;
    nameFirst : string;
    nameLast : string;
    grade : number | null,
    dob : string;
    homePhone : string;
    email : string;
    gender : string | null,
    guardians : [{
        id: string,
        nameFirst: string,
        nameLast: string,
        relationship: string,
        relationshipOther: string,
        phone1: string,
        phone1Type: string,
        phone2: string,
        phone2Type: string,
        email: string,
    }]
};

export type InstructorProgramSchool = {
    id : string;
    shortName : string;
    fullName : string;
};

export type InstructorSectionTerm = {
    id : string;
    createdAt : string;
    updatedAt : string;
    term : InstructorProgramTerm;
};

export type InstructorProgramTerm = {
    id : string;
    semester : string;
    createdAt : string;
    updatedAt : string;
};


type ProgramsState = {
    loading: boolean;
    showAlert: boolean;
    programs: Array<InstructorProgram>;
    message: string;
    alertVariant: string;
}

type Props = {
    children?: ReactNode;
};

export const InstructorProgramsProviderContext = React.createContext<
    ProgramsState
>(
    {
        loading: true,
        showAlert: false,
        programs: [],
        message: '',
        alertVariant: 'success'
    }
);


const programsReducer = (state: ProgramsState, action: any): ProgramsState => {
    switch (action.type) {
        case 'LOADING': {
            return {...state, loading: true, showAlert: false, alertVariant: ''}
        }
        case 'SET_PROGRAMS': {
            return {
                ...state,
                loading: false,
                showAlert: action.showAlert,
                programs: action.programs,
                alertVariant: action.alertVariant,
                message: action.message
            }
        }

        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
};


const fetchInstructorPrograms = async (apiFetch: ApiFetch) => {
    const url = new URL(`/v1/instructor/program`, apiEndpoint);
    const response = await apiFetch(url.toString());

    return await response.json();
}

const loadPrograms = async (apiFetch: ApiFetch, setProgramsState: (param: object) => void, noLoading: boolean | undefined) => {
    const programs = await fetchInstructorPrograms(apiFetch);

    if (!noLoading) {
        setProgramsState({type: 'LOADING'});
    }

    setProgramsState({
        type: 'SET_PROGRAMS',
        programs: programs ?? [],
        showAlert: false
    });
};

const InstructorProgramsProvider: React.FC<Props> = ({children}: Props) => {
    const {user} = useContext(jwtInstructorContext);
    const location = useLocation();
    const history = useHistory();
    const [programsState, setProgramsState] = React.useReducer(programsReducer, {
        loading: true,
        showAlert: false,
        programs: [],
        message: '',
        alertVariant: 'success'
    });

    useEffect(() => {
        if (!user?.apiFetch) {
            return;
        }
        loadPrograms(user.apiFetch, setProgramsState, false);

        const interval = setInterval(() => {
            if (document.hidden || !user?.apiFetch) {//background tab just bail
                return;
            }
            loadPrograms(user.apiFetch, setProgramsState, true);
        }, 5 * 60 * 1000);//update every 5 minutes
        return () => clearInterval(interval);
    }, [user]);

    useEffect(() => {
        if (user && location.pathname === '/instructor/sign-in') {
            history.push('/instructor/');
        }
    }, [user, location])

    return (
        <InstructorProgramsProviderContext.Provider
            value={
                programsState
            }>
            {children}
        </InstructorProgramsProviderContext.Provider>
    );
};

export default InstructorProgramsProvider;

