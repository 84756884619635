import React from 'react';
import GeneralRegisterButton, {
    GeneralRegisterButtonProps
} from "@/components/Program/RegisterButtons/GeneralRegisterButton.tsx";

const RegisteredPendingPaymentButton = (props : GeneralRegisterButtonProps): React.ReactElement => (
    <GeneralRegisterButton
        buttonText="You are Registered pending payment"
        buttonTextAction="Cancelling"
        {...props}
    />
);

export default RegisteredPendingPaymentButton;
