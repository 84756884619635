import React from 'react';
import NavHeader from "../components/Navigation/NavHeader";
import {Col, Container, Row} from "react-bootstrap";
import {ProgramsProviderContext} from "../components/Providers/ProgramsProvider";
import SystemAlert from "../components/SystemAlert";
import RegisterCommunityMemberForm from "../components/Forms/EditCommunityMemberForm";

export const REG_STATUS_SWITCH_PAYMENT = 'switch-payment';

const EditProfile = () => {
    const [programsState,,clearRegistrationError] = React.useContext(ProgramsProviderContext);

    return (
        <>
            <NavHeader showNav={true}/>
            <Container>
                <Row className="d-flex flex-row justify-content-center">
                    <Col sm={12} className="bg-white py-3 mx-0 px-3 main-content mt-5">
                        <RegisterCommunityMemberForm/>
                    </Col>
                </Row>
            </Container>
            <SystemAlert
                show={programsState.showAlert}
                alertText={programsState.message}
                clearSystemAlert={clearRegistrationError}
                variant={programsState.alertVariant}
                isFixed={true}
            />
        </>
    );
};

export default EditProfile;
