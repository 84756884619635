import {useCallback, useContext, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {FormikValues} from "formik";
import {JWTInstructor, jwtInstructorContext, useAuthenticateInstructor,} from "../../components/Providers/JWTProvider";
import SystemAlert from "../../components/SystemAlert";
import InstructorSignInForm from "../../components/Forms/InstructorSignInForm";
import {useHistory} from "react-router-dom";

export const signInError = 'Invalid Username or Password';

const InstructorSignIn = () => {
    const {setUser} = useContext(jwtInstructorContext);
    const authenticateInstructor = useAuthenticateInstructor();
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const history = useHistory();

    const handleSignInSubmit = useCallback(async (values : FormikValues) => {
        const user : JWTInstructor | null = await authenticateInstructor(values.username, values.password);
        if (user) {
            setUser(user);
            history.push('/instructor/');
        } else {
            setShowAlert(true);
        }
    }, [authenticateInstructor, setShowAlert]);

    const handleClearAlert = useCallback(async () => {
        setShowAlert(false);
    }, [setShowAlert]);

    return (
        <>
            <Container>
                <Row className="d-flex flex-row justify-content-center">
                    <Col sm={6} className="bg-white p-3 mx-0 my-5 rounded-sm">
                        <SystemAlert
                            show={showAlert}
                            alertText={signInError}
                            clearSystemAlert={handleClearAlert}
                            variant={'danger'}
                            isFixed={false}
                        />
                        <InstructorSignInForm handleSignInSubmit={handleSignInSubmit}/>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default InstructorSignIn;
