import React from 'react';
import GeneralRegisterButton, {
    GeneralRegisterButtonProps
} from "@/components/Program/RegisterButtons/GeneralRegisterButton.tsx";

const UpdatePaymentButton = (props : GeneralRegisterButtonProps): React.ReactElement => (
    <GeneralRegisterButton
        buttonText="Update Payment"
        buttonTextAction="Switching"
        {...props}
    />
);

export default UpdatePaymentButton;
