import {InstructorProgramCourseSelection} from "../Providers/InstructorProgramsProvider";
import {FilterByActiveOrCanceled} from "../../pages/instructor/ActivityDetailView";
import moment from "moment";
import {MaterialReactTable, type MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {useEffect, useMemo} from "react";
import {notEmpty} from "@/utils/buildRoster.ts";

type Props = {
    courseSection : InstructorProgramCourseSelection;
    showActiveOrCanceled: FilterByActiveOrCanceled;
};

const generateColumns = (courseSection : InstructorProgramCourseSelection) => {
    return [
        {
            accessorKey: 'studentId',
            header: courseSection.allowCommunity ? 'Student / Community Member ID' : 'Student ID',
            size: 220,
        },
        {
            accessorKey: 'studentName',
            header: 'Student Name',
            size: 10,
            accessorFn: (originalRow) => `${originalRow.nameFirst} ${originalRow.nameLast}`,
        },
        {
            accessorKey: 'grade',
            header: 'Grade',
            size: 10,
        },
        {
            accessorKey: 'gender',
            header: 'Gender',
            size: 10,
        },
        {
            accessorKey: 'timestamp',
            header: 'Date Registered',
            size: 10,
            sortingFn: 'datetime',
            Cell: ({row}) => {
                const date = moment(row.original.timestamp);

                if (!date.isValid()) {
                    return "";
                }

                return date.format('MM/DD/YYYY h:mm a');
            },
            accessorFn: (course: DisplayCourse) => {
                const date = moment(course.timestamp);

                if (!date.isValid()) {
                    return "";
                }

                return course.timestamp
            },
        },
    ] satisfies MRT_ColumnDef<DisplayCourse>[];
};

type DisplayCourse = {
    studentId : string;
    studentName : string;
    nameFirst : string;
    nameLast : string;
    grade ?: number;
    gender : string;
    timestamp : string;
};

const ActivityRegistrationTable = (
    {
        courseSection,
        showActiveOrCanceled,
    } : Props) =>
{
    const roster : (DisplayCourse)[] = useMemo(() => {
        return courseSection.registrations.map(registration => {
            if (showActiveOrCanceled === 'active' && registration.status !== null) {
                return null;
            }

            if (showActiveOrCanceled === 'canceled' && registration.status === null) {
                return null;
            }

            if (registration.student) {
                return {
                    studentId: registration.student.id.toString(),
                    studentName: `${registration.student.nameLast}, ${registration.student.nameFirst}`,
                    nameFirst: registration.student.nameFirst,
                    nameLast: registration.student.nameLast,
                    grade: registration.student.grade,
                    gender: registration.student.gender,
                    timestamp: registration.createdAt,
                } as DisplayCourse
            }

            if (registration.communityMember) {
                return {
                    studentId: registration.communityMember.id,
                    studentName: `${registration.communityMember.nameLast}, ${registration.communityMember.nameFirst}`,
                    nameFirst: registration.communityMember.nameFirst,
                    nameLast: registration.communityMember.nameLast,
                    grade: registration.communityMember.grade,
                    gender: registration.communityMember.gender,
                    timestamp: registration.createdAt,
                } as DisplayCourse
            }

            return null;
        }).filter(notEmpty)
    }, [showActiveOrCanceled, courseSection.registrations]);

    const displayCoursesColumns = useMemo<MRT_ColumnDef<DisplayCourse>[]>(
        () => generateColumns(courseSection),
        [showActiveOrCanceled, courseSection],
    );

    const displayCoursesTable = useMaterialReactTable({
        columns: displayCoursesColumns,
        data: roster,
        enableColumnActions: false,
        enableColumnFilters: false,
        enableDensityToggle: false,
        enableFilterMatchHighlighting: false,
        enableFullScreenToggle: false,
        enableGlobalFilter: true,
        enableHiding: false,
        enablePagination: true,
        icons: {
            SortIcon: () => null,
        },
        initialState: {
            columnVisibility: {
                courseSelectionUUID: false,
                statusOriginal: false
            },
            pagination: {pageSize: 40, pageIndex: 0},
        },
        muiTableHeadCellProps: {
            sx: {
                backgroundColor: '#f2e4ff',
                fontWeight: '700',
                fontSize: '14px',
                padding: '12px',
                '& span': {
                    backgroundColor: 'red',
                    display: 'none',
                }
            },
        },
        muiTableBodyCellProps: {
            sx: {
                fontWeight: 'normal',
                fontSize: '14px',
                padding: '12px',
            },
        },
    });

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 990) { // Adjust breakpoint as needed
                displayCoursesTable.setColumnVisibility((prev) => ({
                    ...prev,
                    studentId: true,
                    studentName: true,
                    grade: false,
                    gender: false,
                    timestamp: false,
                }));
            } else {
                displayCoursesTable.setColumnVisibility({
                    studentId: true,
                    studentName: true,
                    grade: true,
                    gender: true,
                    timestamp: true,
                });
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return <MaterialReactTable table={displayCoursesTable}/>
};

export default ActivityRegistrationTable;
