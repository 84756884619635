import Form from "react-bootstrap/Form";

type Props = {
    availablePrograms : Array<string>;
    setActiveProgram : (activeProgram : string) => void;
    defaultProgram? : string;
    className? : string;
};

const ProgramSelectField = ({availablePrograms, setActiveProgram, className, defaultProgram} : Props) => {
    return (
        <Form.Control
            type={'select'}
            as="select"
            name={'availablePrograms'}
            value={defaultProgram}
            onChange={async (e) => {
                setActiveProgram(e.currentTarget.value);
            }}
            disabled={false}
            className={`w-50 ${className}`}
        >
            <option value=""> -- Select --</option>
            {availablePrograms && availablePrograms.map((option , index) => {
                return <option
                    value={option}
                    key={index}
                >{option}</option>;
            })}
        </Form.Control>
    );
};

export default ProgramSelectField;
