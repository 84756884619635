import React, {useState} from 'react';
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import Spinner from "react-bootstrap/Spinner";
import {ProgramCourse} from "@/components/Providers/ProgramsProvider.tsx";
import CourseQuestionModal from "@/components/Modal/CourseQuestionModal.tsx";
import {Box, Stack} from "@mui/material";

export type GeneralRegisterButtonProps = {
    course: ProgramCourse;
    icon ?: IconProp;
    loading: boolean;
    showCourseQuestions ?: boolean;
    callback ?: (course: ProgramCourse) => Promise<void>;
}

type GeneralRegisterButtonWithTextProps = GeneralRegisterButtonProps & {
    buttonText: string;
    buttonTextAction: string;
}

const GeneralRegisterButton = (
    {
        course,
        icon,
        loading,
        buttonText,
        buttonTextAction,
        callback,
        showCourseQuestions = false,
    } : GeneralRegisterButtonWithTextProps
): React.ReactElement => {
    const [showCourseQuestionModal, setShowCourseQuestionModal] = useState<boolean>(false);

    return <>
        <Button
            variant="outline-primary"
            type="submit"
            className="d-flex pt-2 flex-row align-content-center"
            disabled={callback === undefined}
            onClick={async () => {
                if (!callback) {
                    return;
                }

                if (course.courseQuestions && showCourseQuestions) {
                    setShowCourseQuestionModal(true);
                }

                if (!course.courseQuestions || !showCourseQuestions) {
                    await callback(course);
                }
            }}
        >
            {loading ? (
                <React.Fragment>
                    <Spinner animation="border" size="sm" className="mt-1"/>
                    &nbsp; {buttonTextAction}
                </React.Fragment>
            ) : (
                <Stack
                    sx={{
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    {icon && <FontAwesomeIcon icon={icon} size="sm" className="me-2"/>}
                    <Box sx={{pl: 1}}>{buttonText}</Box>
                </Stack>
            )}
        </Button>
        {course.courseQuestions && <CourseQuestionModal
            show={showCourseQuestionModal}
            handleClose={() => setShowCourseQuestionModal(false)}
            course={course}
            performActionBasedOnCourseStatus={callback ?? (async () => {})}
        />}
    </>
};

export default GeneralRegisterButton;
