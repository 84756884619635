import {FieldArray, FormikErrors, FormikValues} from "formik";
import {Col, Row} from "react-bootstrap";
import PhoneField from "./Fields/PhoneField";
import StandardField from "./Fields/StandardField";
import Form from "react-bootstrap/Form";

export interface NameToValueMap {
    [key: string]: any;
}

export type Guardian = {
    firstName: string;
    lastName: string;
    relationship: string;
    relationshipOther: string;
    id?: string;
};
export type List = {
    name: string;
    values: Array<StandardFieldOption>;
};

export type StandardFieldOption = {
    label: string;
    value: string;
    checked: boolean;
};

type Props = {
    handleSetFormIsModified: (formIsModified: boolean) => Promise<void>;
    formValues: FormikValues;
    formErrors: FormikErrors<FormikValues>;
};

const GuardianForm = ({formValues, handleSetFormIsModified}: Props) => {
    return (
        <FieldArray
            name="guardians"
            render={() => {
                return formValues.guardians.map((guardian: Guardian, index: number) =>
                    <Row className="mx-0 mb-3 justify-content-center" key={`guardian-${index}`}>
                        <Col xs={12} className="mt-2">
                            {index === 0 && <h3 style={{color: 'black'}}>Contact 1 – Parent or Guardian</h3>}
                            {index > 0 && <h3 style={{color: 'black'}}>Contact {index + 1} – Parent, Guardian, or Emergency Contact</h3>}
                        </Col>
                        {guardian.id && (<Form.Control
                            type='hidden'
                            name={`guardians[${index}].id`}
                            value={guardian.id}
                        />)}
                        <Col xs={12} lg={6}>
                            <StandardField
                                name="nameFirst"
                                type="text"
                                label="First Name"
                                handleSetFormIsModified={handleSetFormIsModified}
                                arrayName='guardians'
                                iteration={index}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            <StandardField
                                name="nameLast"
                                type="text"
                                label="Last Name"
                                handleSetFormIsModified={handleSetFormIsModified}
                                arrayName='guardians'
                                iteration={index}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            <StandardField
                                name="relationship"
                                type="select"
                                label="Relationship"
                                arrayName='guardians'
                                iteration={index}
                                handleSetFormIsModified={handleSetFormIsModified}
                                options={[
                                    {
                                        checked: false,
                                        label: "Mother",
                                        value: "Mother",
                                    },
                                    {
                                        checked: false,
                                        label: "Father",
                                        value: "Father",
                                    },
                                    {
                                        checked: false,
                                        label: "Other",
                                        value: "Other",
                                    }
                                ]}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            <StandardField
                                name="relationshipOther"
                                type="text"
                                label="Other"
                                handleSetFormIsModified={handleSetFormIsModified}
                                arrayName='guardians'
                                iteration={index}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            <PhoneField
                                name="phone1"
                                label="Phone Number 1"
                                arrayName='guardians'
                                iteration={index}
                                useSetFieldTouched={false}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            <StandardField
                                name="phone1Type"
                                type="select"
                                label="Type"
                                arrayName='guardians'
                                iteration={index}
                                handleSetFormIsModified={handleSetFormIsModified}
                                options={[
                                    {
                                        checked: false,
                                        label: "Home",
                                        value: "Home",
                                    },
                                    {
                                        checked: false,
                                        label: "Work",
                                        value: "Work",
                                    },
                                    {
                                        checked: false,
                                        label: "Mobile",
                                        value: "Mobile",
                                    }
                                ]}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            <PhoneField
                                name="phone2"
                                label="Phone Number 2"
                                arrayName='guardians'
                                iteration={index}
                                useSetFieldTouched={false}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            <StandardField
                                name="phone2Type"
                                type="select"
                                label="Type"
                                arrayName='guardians'
                                iteration={index}
                                handleSetFormIsModified={handleSetFormIsModified}
                                options={[
                                    {
                                        checked: false,
                                        label: "Home",
                                        value: "Home",
                                    },
                                    {
                                        checked: false,
                                        label: "Work",
                                        value: "Work",
                                    },
                                    {
                                        checked: false,
                                        label: "Mobile",
                                        value: "Mobile",
                                    }
                                ]}
                            />
                        </Col>
                        <Col xs={12}>
                            <Row className="mx-0 justify-content-start" key={`guardian-${index}`}>
                                <Col xs={6} className="p-0 pr-3" >
                                    <StandardField
                                        name="email"
                                        type="text"
                                        label="Email Address"
                                        arrayName='guardians'
                                        iteration={index}
                                        handleSetFormIsModified={handleSetFormIsModified}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>)
            }}
        />
    );
};

export default GuardianForm;
