import LogoImage from '../../images/glenbrook225.png';

const Logo = () => (
    <img
        alt="GLENBROOK HIGH SCHOOLS"
        src={LogoImage}
        width="125"
        className="d-inline-block align-top"
    />
);

export default Logo;
