import {useCallback, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import ModalBase from "./ModalBase";
import {CourseQuestion, ProgramCourse} from "../Providers/ProgramsProvider";
import {Formik, FormikValues} from "formik";
import * as yup from 'yup';
import StandardField from "../Forms/Fields/StandardField";

type Props = {
    show: boolean;
    handleClose: () => void;
    course: ProgramCourse;
    performActionBasedOnCourseStatus: (course: ProgramCourse) => Promise<void>;
};

const getInitialValuesFromCourseQuestions = (listOfCourseQuestions : CourseQuestion[]) => {
    let initialValues = {};

    listOfCourseQuestions.map((question) => {
        initialValues = {
            ...initialValues,
            [question.id]: '',
        }
    });
    return initialValues;
}

const getSchemaFromCourseQuestions = (listOfCourseQuestions : CourseQuestion[]) => {
    let yupValidationSchema = {};

    listOfCourseQuestions.map((question) => {
        yupValidationSchema = {
            ...yupValidationSchema,
            [question.id]: yup.string().required('This question is required'),
        }
    });
    return yupValidationSchema;
}

const CourseQuestionModal = ({
    show,
    handleClose,
    course,
    performActionBasedOnCourseStatus
}: Props) => {

    const [,setFormIsModified] = useState<boolean>(false);
    const [formHasSubmitted, setFormHasSubmitted] = useState<boolean>(false);

    const handleSetFormIsModified = useCallback(async (formIsModified: boolean) => {
        setFormIsModified(formIsModified);
    }, [setFormIsModified]);

    const listOfCourseQuestions = course.courseQuestions;

    if (!listOfCourseQuestions) {
        return <>Error: This modal loaded but the course has no questions</>
    }

    const initialValues = getInitialValuesFromCourseQuestions(listOfCourseQuestions);
    const schema = yup.object(getSchemaFromCourseQuestions(listOfCourseQuestions));

    const handleSubmit = async (values : FormikValues) => {
        const arrayOfQuestionIDs = Object.keys(values);

        const listOfCourseQuestionsWithAnswers = listOfCourseQuestions.map((question, index) => {
            let questionID = arrayOfQuestionIDs[index];
            return {...question, userSubmittedAnswer: values[questionID]}
        })

        course = {
            ...course,
            courseQuestions: listOfCourseQuestionsWithAnswers,
        };

        await performActionBasedOnCourseStatus(course);
        setFormHasSubmitted(false);
        handleClose();
    }

    return (
        <ModalBase
            show={show}
            title={"Before registering, please answer the following questions:"}
            size="lg"
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={handleClose}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={handleSubmit}
                validateOnChange={formHasSubmitted}
                validateOnBlur={formHasSubmitted}
            >
                {(props) => {
                    const submitForm = props.submitForm;

                    return <Form.Group>
                        {listOfCourseQuestions.map((question, index) => {
                            return (<Row key={index} className="chris-form-bg mx-0 py-3 px-2">
                                <Col xs={6}>{question.question}
                                    {question.responseType === 'Value List' &&
                                        <StandardField
                                            name={question.id}
                                            className='mt-2'
                                            type="select"
                                            label=""
                                            disabled={false}
                                            handleSetFormIsModified={handleSetFormIsModified}
                                            options={question.answerList?.map((answer) => {
                                                return {checked: false, label: answer, value: answer}
                                            })}
                                        />
                                    }
                                    {question.responseType === 'Free Form' &&
                                        <StandardField
                                            name={question.id}
                                            className='mt-2'
                                            type="text"
                                            label=""
                                            disabled={false}
                                            handleSetFormIsModified={handleSetFormIsModified}
                                        />
                                    }
                                </Col>
                            </Row>)
                        })}
                        <Col xs={12}><hr /></Col>
                        <Row className="mx-0 mt-2">
                            <Col xs={12} className="d-flex flex-row justify-content-start justify-content-lg-end">
                                <Button
                                    variant={"primary"}
                                    type="button"
                                    className="d-flex pt-2 flex-row align-content-center mr-2"
                                    onClick={async () => {
                                        await submitForm();
                                        setFormHasSubmitted(true);
                                    }}
                                >Continue to Registration</Button>
                                <Button
                                    variant="secondary"
                                    type="button"
                                    className="d-flex pt-2 flex-row align-content-center mr-2"
                                    onClick={async () => {
                                        handleClose();
                                    }}
                                >Close</Button>
                            </Col>
                        </Row>
                    </Form.Group>
                }}

            </Formik>
        </ModalBase>);
}

export default CourseQuestionModal;
