import {ReactElement} from 'react';
import {Alert, Button, Collapse} from 'react-bootstrap';

type Props = {
    show : boolean;
    alertText : string | null | ReactElement;
    clearSystemAlert : (() => void) | null;
    timeout? : number;
    variant? : any;
    isFixed? : boolean;
};

const SystemAlert = ({
    show,
    alertText,
    timeout,
    variant,
    clearSystemAlert,
    isFixed
} : Props) => {
    return (
        <Collapse in={show} timeout={timeout ? timeout : 1000}>
            <div className={isFixed ? 'fixed-top' : ''}>
                <Alert variant={variant ? variant : 'success'} className="rounded-0">
                    {clearSystemAlert && (
                        <Button
                            type="button"
                            className="close btn-success"
                            data-dismiss="alert"
                            aria-label="Close"
                            onClick={() => clearSystemAlert()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </Button>
                    )}
                    <span className="justify-content-center d-flex">
                        {alertText}
                    </span>
                </Alert>
            </div>
        </Collapse>
    );
}

export default (SystemAlert);
