import * as React from 'react';
import {Box, Stack} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import CartSummaryRegistrationListItem from "@/components/Cart/CartSummaryRegistrationListItem.tsx";

const CartSummaryList = () : React.ReactElement => {
    const {cart, deleteCartItem} = useCartContext();

    return <Box
        sx={{
            flexGrow: 1,
            overflowY: {xs: 'visible', md: 'auto'},
        }}
    >
        {cart?.cartItems.map(cartItem => <Box
            key={`payment-due-${cartItem.id}`}
            sx={{
                pl: 1,
            }}
        >
            <Stack sx={{flexDirection: 'row', py: 1,}}>
                <CartSummaryRegistrationListItem cartItem={cartItem}/>
                <DeleteIcon
                    onClick={() => {
                        deleteCartItem(cartItem.id);
                    }}
                    sx={{
                        cursor: 'pointer',
                    }}
                />
            </Stack>
        </Box>)}
    </Box>
}

export default CartSummaryList;
