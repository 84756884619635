import {useCallback, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import SignInForm from "../components/Forms/SignInForm";
import {FormikValues} from "formik";
import {JWTUser, useAuthenticateUser} from "../components/Providers/JWTProvider";
import SystemAlert from "../components/SystemAlert";
import moment from "moment";
import SignInHeader from '../components/Navigation/SignInHeader';

export const signInError = 'Invalid Student ID or Birth Date';

const SignIn = () => {
    const authenticateUser = useAuthenticateUser();
    const [showAlert, setShowAlert] = useState<boolean>(false);

    const handleSignInSubmit = useCallback(async (values : FormikValues) => {
        const dob = moment(values.dob, 'MM/DD/YYYY', true);
        const user : JWTUser | null = await authenticateUser(values.studentId, dob.format('YYYY-MM-DD'));

        if (user) {
            window.location.href = '/programs';
        } else {
            setShowAlert(true);
        }
    }, [authenticateUser, setShowAlert]);

    const handleClearAlert = useCallback(async () => {
        setShowAlert(false);
    }, [setShowAlert]);

    return (
        <>
            <SignInHeader showNav={true} />
            <Container>
                <Row className="d-flex flex-row justify-content-center">
                    <Col sm={6} className="bg-white p-3 mx-0 my-5 rounded-sm">
                        <SystemAlert
                            show={showAlert}
                            alertText={signInError}
                            clearSystemAlert={handleClearAlert}
                            variant={'danger'}
                            isFixed={false}
                        />
                        <SignInForm handleSignInSubmit={handleSignInSubmit}/>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default SignIn;
