import {Formik, FormikValues} from "formik";
import {useCallback, useState} from "react";
import {Alert, Button, Col, Row} from "react-bootstrap";
import * as yup from 'yup';
import CircularLoadingIndicator from "../CircularLoadingIndicator";
import StandardField from "../Forms/Fields/StandardField";
import {apiEndpoint} from "../Providers/JWTProvider";
import ModalBase from "./ModalBase";

type Props = {
    show: boolean;
    handleClose: () => void;
    handleYes: () => void;
    isStudent: boolean;
};

const RecoverEmailModal = ({
    show,
    handleClose,
    isStudent = false
}: Props) => {
    const [loading, setLoading] = useState(false);
    const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
    const [, setFormIsModified] = useState<boolean>(false);

    const personVerbiage = isStudent ? `Student` : `Community Member`;

    const handleRecoverRegistration = async (values: FormikValues) => {
        setLoading(true);
        const url = isStudent ? new URL(`/v1/student/recover-student`, apiEndpoint) : new URL(`/v1/community/recover-member`, apiEndpoint);
        let init: RequestInit = {headers: new Headers({'Content-Type': 'application/json'}), method: 'PUT'};
        init.body = JSON.stringify(values);
        fetch(url.toString(), init);
        setLoading(false);
        setShowConfirmationMessage(true);
    };

    const handleSetFormIsModified = useCallback(async (formIsModified: boolean) => {
        setFormIsModified(formIsModified);
    }, [setFormIsModified]);

    const internalClose = () => {
        handleClose();
        setFormIsModified(false);
        setLoading(false);
        setShowConfirmationMessage(false);
    };

    const initialValues = {
        email: '',
    };

    const schema = yup.object({
        email: yup
            .string()
            .transform((value) => {
                return value === null ? '' : value;
            })
            .email("Email must be valid.")
            .required(`Email is required`),
    });

    return (
        <ModalBase
            show={show}
            title={`${personVerbiage} ID`}
            size="md"
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={internalClose}
        >
            {loading && <CircularLoadingIndicator/>}
            {!loading && showConfirmationMessage && <Alert variant={'success'} className="rounded-0">
                <span className="justify-content-center d-flex">
                    If located, an email will be sent to the address on file with your {personVerbiage} ID.
                </span>
            </Alert>}
            {!loading && !showConfirmationMessage && <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={async (values) => {
                    setLoading(true);
                    handleRecoverRegistration(values);
                }}
                enableReinitialize={true}
            >
                {(props) => {
                    return <>
                        <Row className="chris-form-bg mx-0 py-3 px-2">
                            <Col xs={12}>Please enter the email address that was used to register below to recover your {personVerbiage} ID.</Col>
                        </Row>
                        <Row className="chris-form-bg mx-0 py-3 px-2">
                            <Col xs={12}>
                                <StandardField
                                    name="email"
                                    type="text"
                                    label="Email Address"
                                    handleSetFormIsModified={handleSetFormIsModified}
                                    placeHolder="example@website.com"
                                />
                            </Col>
                        </Row>
                        <Col xs={12}><hr /></Col>
                        <Row className="mx-0 mt-2">
                            <Col xs={12} className="d-flex flex-row justify-content-start justify-content-lg-end">
                                <Button
                                    variant="secondary"
                                    type="button"
                                    className="d-flex pt-2 flex-row align-content-center mr-2"
                                    onClick={async () => {
                                        handleClose();
                                    }}
                                >Cancel</Button>
                                <Button
                                    variant="btn-secondary"
                                    style={{backgroundColor: '#631d79', color: 'white'}}
                                    type="submit"
                                    className="d-flex pt-2 flex-row align-content-center"
                                    onClick={async () => {
                                        props.submitForm();
                                    }}

                                >Recover</Button>
                            </Col>
                        </Row>
                    </>
                }}
            </Formik>}
        </ModalBase>
    );
}

export default RecoverEmailModal;
