import * as React from 'react';
import {Box, Stack} from "@mui/material";
import {formatter} from "@/Helpers/formatter.ts";
import moment from "moment/moment";
import {CartItem} from "@/types/CartItem.ts";

type Props = {
    cartItem: CartItem;
};

const CartSummaryRegistrationListItem = ({cartItem} : Props) : React.ReactElement => {
    if (cartItem.itemType === 'fee') {
        return <Box sx={{flexGrow: 1,}}>
            <Box sx={{display: 'inline', pr: 1, color: '#000', fontWeight: 800}}>{cartItem.name} (Fee)</Box>
            <Stack sx={{
                mt: 1,
                px: 1,
                color: '#000',
                fontWeight: 800,
                flexDirection: 'row',
                justifyContent: 'flex-end',
            }}>{formatter.format(cartItem.cost)}</Stack>
        </Box>;
    }

    return <Box sx={{flexGrow: 1,}}>
        <Box sx={{display: 'inline', pr: 1, color: '#000', fontWeight: 800}}>{cartItem.name}</Box>
        {cartItem.deliveryMethod &&
            <Box sx={{display: 'inline', pr: 1, color: '#000'}}>{cartItem.deliveryMethod && ','}</Box>}
        <Box sx={{display: 'inline', pr: 1, fontWeight: 800, color: '#000'}}>Section {cartItem.sectionNumber}</Box>

        <Box sx={{px: 1}}>{moment(cartItem.dateStartFull).format('MM/DD')} - {moment(cartItem.dateEndFull).format('MM/DD')},</Box>
        <Box sx={{px: 1, display: 'inline'}}>{cartItem.meetingTime}</Box>

        <Box sx={{px: 1, color: '#000', fontWeight: 800}}>{formatter.format(cartItem.cost)}</Box>
    </Box>;
}

export default CartSummaryRegistrationListItem;
