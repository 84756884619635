import ModalBase from './ModalBase';
import {Jumbotron} from "react-bootstrap";
import {useNotice} from "@/components/Providers/NoticeProvider.tsx";

type Props = {
    showPartialPaymentsModal : boolean;
    setShowPartialPaymentsModal : (showPartialPaymentsModal : boolean) => void;
    setShowPartialPayments : (showPartialPayments : boolean) => void;
}

const PartialPaymentsModal = ({showPartialPaymentsModal, setShowPartialPaymentsModal, setShowPartialPayments} : Props) => {
    const [notice] = useNotice();

    const handleCloseModal = () => {
        setShowPartialPaymentsModal(false);
    };

    const handleShowPartials = () => {
        setShowPartialPaymentsModal(false);
        setShowPartialPayments(true);
    }

    return (
        <ModalBase
            show={showPartialPaymentsModal}
            title="Partial Payments"
            size="lg"
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={handleCloseModal}
        >
            <Jumbotron className="mt-0 px-0">
                <div className="markdown">
                    <p>{notice.partiallyCompletedPaymentMessage}</p>

                    <a href={'#'} onClick={handleShowPartials}>Click here to view your partially completed payments</a>
                </div>
            </Jumbotron>
        </ModalBase>
    );
};

export default PartialPaymentsModal;
