import {Button, Col, Row} from "react-bootstrap";
import ModalBase from "./ModalBase";
import {useNotice} from "../Providers/NoticeProvider";
import {ProgramCourse} from "../Providers/ProgramsProvider";

type Props = {
    course: ProgramCourse;
    show: boolean;
    handlePaymentCollectedModal: () => void;
};

const PaymentCollectedModal = ({
   course,
    show,
    handlePaymentCollectedModal,
}: Props) => {
    const [notice] = useNotice();

    return (
        <ModalBase
            show={show}
            title=""
            size="lg"
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={handlePaymentCollectedModal}
        >
            <Row className="chris-form-bg mx-0 py-3 px-2">
                <Col xs={12}>{course.programCancellationMessage !== '' ? course.programCancellationMessage : notice.programCancellationMessage}</Col>
            </Row>
            <Col xs={12}><hr /></Col>
            <Row className="mx-0 mt-2">
                <Col xs={12} className="d-flex flex-row justify-content-start justify-content-lg-end">
                    <Button
                        variant="secondary"
                        type="button"
                        className="d-flex pt-2 flex-row align-content-center mr-2"
                        onClick={async () => {
                            handlePaymentCollectedModal();
                        }}
                    >Close</Button>
                </Col>
            </Row>
        </ModalBase>);
}

export default PaymentCollectedModal;
